import React, { Component } from "react";
import { connect } from "react-redux";
import { getSejoursEvents__action, updateSejoursEvents__action } from "../../../../../../actions/sejours.events.actions";
import { deleteSejoursPrices__action } from "../../../../../../actions/sejours.prices.actions";
import ConfirmDialog from "../../../../../../components/custom-boostrap/ConfirmDialog";
import { apiRequest } from "../../../../../../functions/api";
import { pushNotification } from "../../../../../../functions/notifications";
import { getDeepState, setDeepState } from "../../../../../../functions/react.utilities";

class RemoveModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      pendingSelection : {},
      loading : false,
      loadingMess : ""
    }

    this.getDeepState = getDeepState(this);
    this.setDeepState = setDeepState(this);

    this.pState = () => ({ ...this.props.parentState });
    this.pSetState = this.props.parentSetState;
    this.pSetDeepState = this.props.parentSetDeepState;
    this.pGetDeepState = this.props.parentGetDeepState;

    this.pSetState = this.pSetState.bind(this);
    this.pSetDeepState = this.pSetDeepState.bind(this);
    this.pGetDeepState = this.pGetDeepState.bind(this);

    this.cancel = this.cancel.bind(this);
    this.removePrices = this.removePrices.bind(this);
  }

  componentDidUpdate(prevProps){
    if(this.props.show && (this.props.show !== prevProps.show)){
      this.setState({ loading : false, loadingMess : "" }); 
    }
  }

  removePrices(){
    if(this.props.PIDS.length > 0){
      const newState = this.getDeepState();
      const selection = this.props.PIDS;

      newState.loading = true;
      newState.loadingMess = "Retrait des tarifs en cours...";

      let prices = [];
      this.props.sejours.events[this.props.EID].prices.forEach((PID) => {
        if(!selection.includes(PID)){
          prices.push(PID);
        }
      });
      console.log(prices);

      apiRequest({
          url : "/sejours.events/put",
          data : {
              EID : this.props.EID,
              event : { prices }
          }
      })
      .then((res) => {
          const newParentState = this.pGetDeepState();
          const newState = this.getDeepState();
          const errorNotification = (m) => pushNotification({
              type : "error",
              title : `Retrait ${(selection.length > 1) ? "des tarifs" : "du tarif"} impossible`,
              icon : "mdi mdi-close",
              content : <p>{m}</p>
          });

          if(res.data.http_code === 200){
              if(res.data.status === "success"){
                  if(res.data.data.event){
                    newParentState.selection = [];
                    selection.forEach((PID) => delete newParentState.configs[PID]);
                    this.props.dispatch(getSejoursEvents__action({ [res.data.data.event.EID] : res.data.data.event }));
                    pushNotification({
                        type : "success",
                        title : (selection.length > 1) ? "Tarifs retirés" : "Tarif retiré",
                        icon : "mdi mdi-delete-forever",
                        content : <p>{`${(selection.length > 1) ? "Tarifs retirés" : "Tarif retiré"} avec succès !`}</p>
                    });
                  }else{
                      errorNotification(`Erreur du serveur (UNKNOWN)`);
                  }
              }else{
                  errorNotification(`Erreur du serveur (${res.data.status_code})`);
              }
          }else{
              errorNotification(`Erreur du serveur (${res.data.status_code})`);
          }

          newState.loading = false;
          newState.loadingMess = false;

          newParentState.modals.delete = false;

          this.setState(newState);
          this.pSetState(newParentState);
        });

      this.setState(newState);
    }
  }

  cancel(){
    this.pSetDeepState(s => s.modals.delete = false);
  }

  render() {
    return (
      <ConfirmDialog 
          show = {this.props.show}
          loading={this.state.loading}
          loadingMess={this.state.loadingMess}
          loadingInline={true}
          modalTitle = "Retirer des tarifs"
          decline = {{ text : "Annuler", variant : "secondary", onClick : this.cancel, disabled : this.state.loading }}
          confirm = {{ text : "Valider", onClick : this.removePrices, disabled : this.state.loading }}
          onHide = {this.cancel}
      >
          Retirer {`${(this.props.PIDS.length > 1) ? `les ${this.props.PIDS.length} tarifs sélectionnés` : "le tarif sélectionné"} ?`}
      </ConfirmDialog>
    );
  }
}

const mapStateToProps = (state) => ({ sejours : state.sejours });
export default connect(mapStateToProps, null)(RemoveModal);