import * as appActions from '../constants/app.actions';
import * as usersActions from '../constants/users.actions';
import { apiRequest } from '../functions/api';


export const getUser = async (dispatch) => {
    return apiRequest({
        url : '/user.infos/get'
    })
    .then((res) => {
        if(res.data.http_code == 200 && res.data.status_code === "OK" && res.data.data.user){
            const { user } = res.data.data;
            dispatch({ 
                type : appActions.SET_USER,
                payload : {
                    user,
                    loggedIn : true
                }
            });
        }else{
            dispatch({ 
                type : appActions.SET_USER, 
                payload : {
                    user : null,
                    loggedIn : false
                }
            });
        }
    })
    .catch(() => {})
}

export const updateUser = ({ user, loggedIn }) => {
    return { 
            type : appActions.UPDATE_USER, 
            payload : {
                user,
                loggedIn
            }
        }
}

export const setUser = ({ user, loggedIn }) => {
    return {
        type : appActions.SET_USER,
        payload : {
            user,
            loggedIn
        }
    }
}
