import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import SejoursEvents from './index';
import SejourEvent from '../events/event/';

class SejourEventsRouter extends Component{
    constructor(props){
        super(props);
        console.log(props);
    }
    render(){
        return(
            <Switch>
                {/* Models */}
                <Route exact path={this.props.path} component={SejoursEvents} />

                {/* Model */}
                <Route exact path={`${this.props.path}/:EID`} component={SejourEvent} />
                <Route path={`${this.props.path}/:EID/:tab`} component={SejourEvent} />
            </Switch>
        )
    }
    
}

export default SejourEventsRouter;