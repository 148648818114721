import * as globalsActions from "../constants/sejours.globals.actions"

export const getSejoursModelsEventsPrices__action = (data) => {
    return {
        type : globalsActions.GET_SEJOURS_MODELS_EVENTS_PRICES,
        payload : data
    }
}

export const updateSejoursModelsEventsPrices__action = (data) => {
    return {
        type : globalsActions.UPDATE_SEJOURS_MODELS_EVENTS_PRICES,
        payload : data
    }
}

export const deleteSejoursModelsEventsPrices__action = (data) => {
    return {
        type : globalsActions.DELETE_SEJOURS_MODELS_EVENTS_PRICES,
        payload : data
    }
}