export const setPageTitle = (title) => {
    document.title = `${title} - ${process.env.REACT_APP_NAME}`;
}
export const isValidEmail = (email) => {
    var RegExpMail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return RegExpMail.test(email);
}

export const isValidPhone = (number) => {
    var RegExpPhone = new RegExp("^0[1-9]([-. ]?[0-9]{2}){4}$");
	return RegExpPhone.test(number)
}

export const isEmpty = (value) => {
    switch (typeof value) {
        case "string":
            return value.trim() === "" || value === "undefined";

        case "object":
            if(value){
                return Object.keys(value).length === 0;
            }else{
                return true;
            }

        default:
            return (value ? false : true);
    }
}

export const lengthObject = (o) => Object.keys(o).length;