import * as eventsActions from '../constants/sejours.events.actions';

export const getSejoursEvents__action = (data) => {
    return {
        type : eventsActions.GET_SEJOURS_EVENTS,
        payload : data
    }
};

export const updateSejoursEvents__action = (data) => {
    return {
        type : eventsActions.UPDATE_SEJOURS_EVENTS,
        payload : data
    }
}

export const deleteSejoursEvents__action = (data) => {
    return {
        type : eventsActions.DELETE_SEJOURS_EVENTS,
        payload : data
    }
};