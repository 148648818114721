import React, { Component } from "react";
import { Tabs } from "react-bootstrap";

class CTabVertical extends Component{
    getProps(){
        const props = {
            container : {
                className : "tabs-vertical"
            },
            tabs : {
                ...this.props
            }
        }

        if(this.props.variant && this.props.variant === "right"){
            props.container.className += " tabs-right";
        }else{
            props.container.className += " tabs-left";
        }
        

        if(this.props.verticalText){
            props.container.className += " tabs-vertical-text";
        }

        delete props.tabs.children;
        delete props.tabs.variant;
        delete props.tabs.verticalText;

        return props;
    }

    render(){
        const { container, tabs } = this.getProps();
        return ( <div {...container}>
                    <Tabs {...tabs}>
                        {this.props.children}
                    </Tabs>
                </div>
        );
    }
}

export default CTabVertical;