import models from "./sub-reducers/sejours/models.reducer";
import events from "./sub-reducers/sejours/events.reducer";
import prices from "./sub-reducers/sejours/prices.reducer";
import globals from "./sub-reducers/sejours/globals.reducers";

const initialState = { 
    models : {},
    events : {},
    prices : {}
};

export default function sejours(state = initialState, action) {
    let n_state = models(state, action);
    n_state = events(n_state, action);
    n_state = prices(n_state, action);
    n_state = globals(n_state, action);

    return n_state;
}