import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';

class SidebarComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true}); 
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/sejours', state: 'sejoursMenuOpen'},
      {path:'/registrations', state: 'registrationsMenuOpen'}
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
 
  }

  get user() {
    if (this.props.state.app.loggedIn) {
      return this.props.state.users[this.props.state.app.user] || {};
    } else {
      return {}
    }
  }

  render () {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <Link className="sidebar-brand brand-logo" to="/"><img src={`${process.env.REACT_APP_RESSOURCES_URL}/gesprit/gesprit-logo.png`} alt="Logo Ges'prit" /></Link>
          <Link className="sidebar-brand brand-logo-mini" to="/" ><img src={`${process.env.REACT_APP_RESSOURCES_URL}/gesprit/gesprit-logo-mini.png`} alt="Logo Ges'prit" /></Link>
        </div>
        <ul className="nav">
          <li className="nav-item profile">
            <div className="profile-desc">
              <div className="profile-pic">
                <Dropdown>
                  <Dropdown.Toggle as="a" className="cursor-pointer no-caret">
                    <div className="status-indicator">
                      <img className="img-xs rounded-circle " src={this.user.profilImage} alt="profile" />
                      <span className={`count ${this.state.user === "online" ? 'bg-success' : 'bg-danger' }`}/>
                    </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <p><i className="mdi mdi-account-check text-success" />En ligne</p>
                      </Dropdown.Item>
                      <Dropdown.Divider/>
                      <Dropdown.Item>
                        <p><i className="mdi mdi-bell-off text-warning" />Ne pas déranger</p>
                      </Dropdown.Item>
                      <Dropdown.Divider/>
                      <Dropdown.Item>
                      <p><i className="mdi mdi-account-off text-danger" />Hors ligne</p>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <div className="profile-name">
                  <h5 className="mb-0 font-weight-normal">{`${this.user.firstName} ${this.user.name}`}</h5>
                </div>
              </div>
            </div>
          </li>
          <li className="nav-item nav-category">
            <span className="nav-link">Navigation</span>
          </li>
          <li className={ this.isPathActive('/dashboard') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
              <span className="menu-title">Tableau de bord</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/sejours') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ this.state.sejoursMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('sejoursMenuOpen') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-terrain"></i>
              </span>
              <span className="menu-title">Séjours</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.sejoursMenuOpen }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ this.isPathActive('/sejours/models') ? 'nav-link active' : 'nav-link' } to="/sejours/models">Modèles</Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/sejours/events') ? 'nav-link active' : 'nav-link' } to="/sejours/events">Évènements</Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/sejours/prices') ? 'nav-link active' : 'nav-link' } to="/sejours/prices">Tarifs</Link></li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/registrations') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ this.state.registrationsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('registrationsMenuOpen') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-folder-multiple"></i>
              </span>
              <span className="menu-title">Inscriptions</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.registrationsMenuOpen }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ this.isPathActive('/registrations/registrants') ? 'nav-link active' : 'nav-link' } to="/registrations/registrants">Inscrits</Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/registrations/payments') ? 'nav-link active' : 'nav-link' } to="/registrations/payments">Paiements</Link></li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li className="nav-item nav-category">
            <span className="nav-link">Outils</span>
          </li>
          <li className={ this.isPathActive('/other-users') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
          <Link className="nav-link" to="/other-users">
              <span className="menu-icon">
                <i className="mdi mdi-account-multiple" />
              </span>
              <span className="menu-title">Autres utilisateurs</span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

const mapStateToProps = (state) => ({ state });
const Sidebar = connect(mapStateToProps, null)(SidebarComponent)
export default withRouter(Sidebar);