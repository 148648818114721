import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteSejoursPrices__action } from "../../../../../../actions/sejours.prices.actions";
import ConfirmDialog from "../../../../../../components/custom-boostrap/ConfirmDialog";
import { apiRequest } from "../../../../../../functions/api";
import { pushNotification } from "../../../../../../functions/notifications";
import { getDeepState, setDeepState } from "../../../../../../functions/react.utilities";

class DeleteModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      pendingSelection : {},
      loading : false,
      loadingMess : ""
    }

    this.getDeepState = getDeepState(this);
    this.setDeepState = setDeepState(this);

    this.pState = () => ({ ...this.props.parentState });
    this.pSetState = this.props.parentSetState;
    this.pSetDeepState = this.props.parentSetDeepState;
    this.pGetDeepState = this.props.parentGetDeepState;

    this.pSetState = this.pSetState.bind(this);
    this.pSetDeepState = this.pSetDeepState.bind(this);
    this.pGetDeepState = this.pGetDeepState.bind(this);

    this.cancel = this.cancel.bind(this);
    this.deletePrices = this.deletePrices.bind(this);
  }

  componentDidUpdate(prevProps){
    if(this.props.show && (this.props.show !== prevProps.show)){
      this.setState({ loading : false, loadingMess : "" }); 
    }
  }

  deletePrices(){
    if(this.props.PIDS.length > 0){
      const newState = this.getDeepState();
      const selection = this.props.PIDS;

      newState.loading = true;
      newState.loadingMess = "Suppression en cours...";

      apiRequest({
          url : "/sejours.prices/delete",
          data : {
              PIDS : selection
          }
      })
      .then((res) => {
          const newParentState = this.pGetDeepState();
          const newState = this.getDeepState();
          const errorNotification = (m) => pushNotification({
              type : "error",
              title : `Suppression ${(selection.length > 1) ? "des tarifs" : "du tarif"} impossible`,
              icon : "mdi mdi-close",
              content : <p>{m}</p>
          });

          if(res.data.http_code === 200){
              if(res.data.status === "success"){
                  if(res.data.data.deleted){
                    newParentState.selection = [];
                    selection.forEach((PID) => delete newParentState.configs[PID]);
                    this.props.dispatch(deleteSejoursPrices__action({
                      PIDS : selection,
                      events : res.data.data.events ? res.data.data.events : {},
                      models : res.data.data.models ? res.data.data.models : {}
                    }));
                    pushNotification({
                        type : "success",
                        title : (selection.length > 1) ? "Tarifs supprimés" : "Tarif supprimé",
                        icon : "mdi mdi-delete-forever",
                        content : <p>{`${(selection.length > 1) ? "Tarifs supprimés" : "Tarif supprimé"} avec succès !`}</p>
                    });
                  }else{
                      errorNotification(`Erreur du serveur (UNKNOWN)`);
                  }
              }else{
                  errorNotification(`Erreur du serveur (${res.data.status_code})`);
              }
          }else{
              errorNotification(`Erreur du serveur (${res.data.status_code})`);
          }

          newState.loading = false;
          newState.loadingMess = false;

          newParentState.modals.delete = false;

          this.setState(newState);
          this.pSetState(newParentState);
        });

      this.setState(newState);
    }
  }

  cancel(){
    this.pSetDeepState(s => s.modals.delete = false);
  }

  render() {
    return (
      <ConfirmDialog 
          show = {this.props.show}
          loading={this.state.loading}
          loadingMess={this.state.loadingMess}
          loadingInline={true}
          modalTitle = "Supprimer des tarifs"
          decline = {{ text : "Annuler", variant : "secondary", onClick : this.cancel, disabled : this.state.loading }}
          confirm = {{ text : "Valider", onClick : this.deletePrices, disabled : this.state.loading }}
          onHide = {() => {}}
      >
          Supprimer {`${(this.props.PIDS.length > 1) ? `les ${this.props.PIDS.length} tarifs sélectionnés` : "le tarif sélectionné"} ?`}
      </ConfirmDialog>
    );
  }
}

const mapStateToProps = (state) => ({ sejours : state.sejours });
export default connect(mapStateToProps, null)(DeleteModal);