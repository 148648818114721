import React, { Component } from 'react';
import { setPageTitle } from '../../functions/common';
import RegistrationsPaymentsSearcher from '../../components/pages/registrations/payments';

class RegistrationsPayments extends Component {
    constructor(props) {
        super(props);
        setPageTitle("Paiements");
    }
    render() {
        return <div>
            <h2>Paiements</h2>
            <RegistrationsPaymentsSearcher />
        </div>
    }
}

export default RegistrationsPayments;