import React, { Component } from "react";
import { Badge, Button, Form, FormControl, FormGroup, FormLabel, Modal, ProgressBar } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isValidEmail, isValidPhone } from '../../../functions/common'
import { setUser, updateUser } from '../../../actions/app.actions';
import { apiRequest, apiUploadRequest } from "../../../functions/api";
import * as apiCodes from '../../../constants/api/index';
import ButtonInputFile from "../../custom-boostrap/InputFile";
import ConfirmDialog from "../../custom-boostrap/ConfirmDialog";
import { pushNotification } from "../../../functions/notifications";
import moment from 'moment'

class userInfosComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
        user : this.user,
        inEdition : false,
        avatarEdition : false,
        avatarChanged : false,
        avatarTempFile : null,
        onLoadPreviewer : () => {}, 
        uploadingProgressBar : false, 
        uploadProgress : 0,
        confirmAvatarDeletion : false,
        isValid : true,
        errors : {}
    }
  }

  errors = {
      empty : "EMPTY",
      alreadyEmail : "ALREADY_EMAIL",
      emailSyntaxe : "EMAIL_SYNTAXE",
      alreadyPhone : "ALREADY_PHONE",
      phoneSyntaxe : "PHONE_SYNTAXE"
  }

  get user() {
    if (this.props.app.user) {
        return (this.props.users[this.props.app.user]);
    } else {
        return {};
    }
  }

  get loaded() {
    return !isEmpty(this.user)
  }

  get memberSince() {
    if (this.user.createdAt) {
        const date = moment(this.user.createdAt);
        return (`Membre depuis le ${date.format("D MMMM YYYY")}`)
    } else {
        return (null)
    }
  }

  editInfos(){
    this.setState({
        ...this.state,
        user : this.user, 
        inEdition : true,
        isValid : false,
        errors : {}
    }); 
  }

  userInfosEdition(){
      const handleChange= (e) => {
        let user = { ...this.state.user, [e.target.name] : e.target.value };
        let errors = {};
        let isValid = true;

        const setError = (err) => {
            errors[err.property] = err.code;
            isValid = false;
        }
        

        if(isEmpty(user.name)) { setError({ property : 'name', code : this.errors.empty }) }

        if(isEmpty(user.firstName)) { setError({ property : 'firstName', code : this.errors.empty }) }

        if(isEmpty(user.email)) { setError({ property : 'email', code : this.errors.empty }) }
        else if(!isValidEmail(user.email)){ setError({ property : 'email', code : this.errors.emailSyntaxe }) }

        if(isEmpty(user.phone)){ setError({ property : 'phone', code : this.errors.empty }) }
        else if(!isValidPhone(user.phone)){ setError({ property : 'phone', code : this.errors.phoneSyntaxe }) }

        if(isValid){
            isValid = (
                this.user.name != user.name ||
                this.user.firstName != user.firstName ||
                this.user.email != user.email ||
                this.user.phone != user.phone 
            )
        }

        this.setState({ user : user, errors : errors, isValid : isValid });
      }

      const handleValidation = () => {
        let tmpState = {
            inEdition : true,
            errors : {
                ...this.state.errors
            }
        }
        if(this.state.isValid){
            apiRequest({
                url : '/user.infos/put',
                data : {
                    user : this.state.user
                }
            })
            .then((res) => {
                if(res.data.http_code == 200){
                    if(res.data.status == "success" && res.data.data.user){
                        tmpState.inEdition = false;
                        this.props.dispatch(updateUser({ user : {...res.data.data.user}, loggedIn : true }));
                        this.setState({ ...this.state, ...tmpState });
                        pushNotification({
                            title : "Informations enregistrées",
                            icon : "mdi mdi-account-card-details",
                            content : <p>Vos informations ont été modifées avec succès !</p>,
                            type : "success",
                            autoClose : 5000,
                            closeOnClick : true,
                            draggable : true,
                            pauseOnHover : true
                        });
                    }else{
                        if(res.data.errors){
                            if(res.data.errors.email){
                                if(res.data.errors.email == apiCodes.ALREADY_EMAIL) tmpState.errors.email = this.errors.alreadyEmail
                                if(res.data.errors.email == apiCodes.EMAIL_SYNTAXE) tmpState.errors.email = this.errors.emailSyntaxe
                            }
                            if(res.data.errors.phone){
                                if(res.data.errors.phone == apiCodes.ALREADY_PHONE) tmpState.errors.phone = this.errors.alreadyPhone
                                if(res.data.errors.phone == apiCodes.PHONE_SYNTAXE) tmpState.errors.phone = this.errors.phoneSyntaxe
                            }
                        }
                        this.setState({ ...this.state, ...tmpState });
                    }
                }
                
            })
            .catch(() => {})
        }
      }

      const setFeedBack = ({ name, errors = new Map()}) => {
        if(this.state.errors[name]){
            return (
                <React.Fragment>
                    <Form.Control.Feedback type="invalid">{ errors.get(this.state.errors[name]) }</Form.Control.Feedback>
                </React.Fragment>
            )
        }
      }
      const setControlErrors = (errors = []) => {
        let errorsWithMessages = new Map();
        errors.forEach(error => {
            errorsWithMessages.set(error[0], error[1]);
        });
        return errorsWithMessages;
      }

      const cancelEdit = () => {this.setState({ inEdition : false, errors : {} })}
      return (
        <div>
            <Form autoComplete="off" onSubmit={handleValidation}>
                <Form.Group className="form-group">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control type="text" placeholder="Nom" value={this.state.user.name} name="name" onChange={handleChange.bind(this)} autoComplete="off"/>
                    {setFeedBack({name : 'name', errors : setControlErrors([[this.errors.empty, "Entrez un nom"]])}) }
                </Form.Group>
                <FormGroup className="form-group">
                    <FormLabel>Prénom</FormLabel> 
                    <FormControl type="text" placeholder="Prénom" value={this.state.user.firstName} name="firstName" onChange={handleChange.bind(this)} autoComplete="off"/>
                    {setFeedBack({name : 'firstName', errors : setControlErrors([[this.errors.empty, "Entrez un prénom"]])}) }
                </FormGroup>
                <FormGroup className="form-group">
                    <FormLabel>Email</FormLabel> 
                    <FormControl type="email" placeholder="Email" value={this.state.user.email} name="email" onChange={handleChange.bind(this)} autoComplete="off" />
                    {setFeedBack({name : 'email', errors : setControlErrors([[this.errors.empty, "Entrez un email"], [this.errors.emailSyntaxe, "Email invalide"], [this.errors.alreadyEmail, "Email déjà utilisé"]])}) }
                </FormGroup>
                <FormGroup className="form-group">
                    <FormLabel>Téléphone</FormLabel> 
                    <FormControl type="phone" placeholder="Téléphone" value={this.state.user.phone ? this.state.user.phone : ""} name="phone" onChange={handleChange.bind(this)} autoComplete="off"/>
                    {setFeedBack({name : 'phone', errors : setControlErrors([[this.errors.empty, "Entrez un numéro de téléphone"], [this.errors.phoneSyntaxe, "Numéro invalide"], [this.errors.alreadyPhone, "Numéro déjà utilisé"]])}) }
                </FormGroup>
                <div className="btn-container-inline-center">
                    <Button variant="primary" disabled={ !this.state.isValid } onClick={handleValidation} type="button"><i className="mdi mdi-check"/>Enregistrer</Button>
                    <Button variant="secondary" onClick={cancelEdit.bind(this)} type="button"><i className="mdi mdi-close"/>Annuler</Button>
                </div>
            </Form>
        </div>
      )
  }  

  userInfosDefault(){
      const skeleton = () => {
          return (
            <React.Fragment>
                <h3 className="u-infos-names skeleton primary rnd" />
                <p className="u-infos-link skeleton secondary rnd"/>
                <p className="u-infos-link skeleton secondary rnd"/> 
                <Badge className="u-infos-type skeleton secondary rnd"/>
                <p className="u-infos-mb-since skeleton secondary rnd"/>
            </React.Fragment>
          )
      }
      const corps = () => {
          return(
            <React.Fragment>
                <h3 className="u-infos-names">{`${this.user.firstName} ${this.user.name}`} </h3>
                <p className="u-infos-link"><a title="Envoyer un courrier électronique" target="_blank" href={`mailto:${this.user.email}`}>{this.user.email}</a></p> 
                {this.user.phone ? 
                    <p className="u-infos-link"><a title="Appeler" href={`tel:${this.user.phone}`}>{this.user.phone}</a></p> 
                    : 
                    null 
                }
                <Badge className="u-infos-type" bg={this.user.type === apiCodes.ADMIN ? 'warning' : 'primary'}>{this.user.type === apiCodes.ADMIN ? 'ADMINISTRATEUR' : 'STANDARD'}</Badge>
                {this.user.guide ? <Badge className="u-infos-type is-guide" bg={'success'}>ACCOMPAGNATEUR</Badge> : null}
                <p className="u-infos-mb-since">{this.memberSince}</p>
            </React.Fragment>
          );
      }
      return(
        <div>
            {this.loaded ? corps() : skeleton()}
        </div>
      )
  }
  
  editAvatar() { this.setState({ ...this.state, user : this.user, avatarEdition : true, avatarChanged : false, uploadingProgressBar : false })};

  previewImage({event, files}){
      if(files.length > 0){
        let URLPrevImage = URL.createObjectURL(files[0]);
        let onLoadPreviewer = () => { URL.revokeObjectURL(URLPrevImage) }
        this.setState({ avatarTempFile : files[0], user : { ...this.state.user, profilImage : URLPrevImage}, onLoadPreviewer : onLoadPreviewer, avatarChanged : true });
      }
  }

  saveProfil({ del = false }){
    if(!del){
        const uploadingProgression = (e) => {
            let progress = (e.loaded / e.total) * 100;
            this.setState({ uploadProgress : progress });
        }
        if(this.state.avatarChanged && this.state.avatarTempFile){
            let avatarFile;
            if(!del){
                avatarFile = new FormData();
                avatarFile.append('avatar', this.state.avatarTempFile);
            }else{
                avatarFile = { avatar : "default" };
            }
            apiUploadRequest({
                url : '/user.uploading-profil/put',
                data : avatarFile
            }, uploadingProgression)
            .then((res) => {
                if(res.data.http_code == 200){
                    if(res.data.status == "success" && res.data.data.user){
                        this.setState({ avatarEdition : false });
                        this.props.dispatch(setUser({ user : {...res.data.data.user} , loggedIn : true }));
                    }
                }
            })
        }
        this.setState({ uploadingProgressBar : true, uploadProgress : 0 });
    }else{
        apiRequest({
            url : '/user.uploading-profil/delete'
        })
        .then((res) => {
            if(res.data.http_code == 200){
                if(res.data.status == "success" && res.data.data.user){
                    this.setState({ avatarEdition : false });
                    this.props.dispatch(setUser({ user : {...res.data.data.user} , loggedIn : true }));
                }
            }
        })
    }
  }

  deleteCurrentProfilConfirmation(){
      this.setState({ confirmAvatarDeletion : true });
  }
  deleteCurrentProfil(){
    this.saveProfil({ del : true });
    this.setState({ confirmAvatarDeletion : false });
  }

  avatarEditModal(){
    let onHide = () => {this.setState({ avatarEdition : false, avatarTempFile: null })}
    return(
        <>
        <Modal size="sm" show={this.state.avatarEdition} onHide={onHide}>
            <Modal.Header closeButton>Editer le profil</Modal.Header>
            <Modal.Body>
                <div className="col">
                    <div className="row justify-content-center avatar-img">
                        <img className="img-xl rounded-circle" src={this.state.user.profilImage} onLoad={this.state.onLoadPreviewer} alt="profile"/>
                    </div>
                    <ProgressBar className="progress-avatar-upload" size=""hidden={!this.state.uploadingProgressBar} label={`${this.state.uploadProgress}%`} now={this.state.uploadProgress} variant={this.state.uploadProgress == 100 ? "success" : "primary"}/>
                    <div className="row justify-content-center avatar-modal-btn-grp">
                        <ButtonInputFile onChange={this.previewImage.bind(this)} accept="image/jpg, image/png"><i className="mdi mdi-folder"/>Parcourir</ButtonInputFile>
                        <Button variant="danger" onClick={this.deleteCurrentProfilConfirmation.bind(this)}><i className="mdi mdi-delete-forever"/>Supprimer</Button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="mx-auto">
                <Button variant="primary" disabled={!this.state.avatarChanged} onClick={this.saveProfil.bind(this)}>Enregistrer</Button>
                <Button variant="secondary" onClick={onHide}>Annuler</Button>
            </Modal.Footer>
        </Modal>
        <ConfirmDialog 
            closeButton
            show={this.state.confirmAvatarDeletion}
            modalTitle="Confirmer la suppression"
            onHide={() => this.setState({ confirmAvatarDeletion : false })}
            confirm={{ text : "Confirmer", onClick : this.deleteCurrentProfil.bind(this)}}
            decline={{ text : "Annuler"}}
        >
            <p>Voulez-vous vraiment supprimer cet avatar ? Vous ne pourrez plus annuler cette action.</p>
        </ConfirmDialog>
        </>
    )
  }
  avatar(){
      const skeleton = () => {
          return(
            <React.Fragment>
                <div className="row justify-content-center avatar-img">
                    <div className="img-xl rounded-circle skeleton"/>
                </div>
            </React.Fragment>
          )
      }
      const corps = () => {
          return (
            <React.Fragment>
                <div className="row justify-content-center avatar-img">
                    <img className="img-xl rounded-circle" src={this.user.profilImage} alt="profile"/>
                </div>
                <div className="row justify-content-center">
                    <Button size="sm" onClick={this.editAvatar.bind(this)}>Modifier</Button>
                </div>
            </React.Fragment>
          );
      }
      return(
          <React.Fragment>
              {this.loaded ? corps() : skeleton()}
          </React.Fragment>
      )
  }

  render() {
    return (
        <div className="row">
          <div className="col grid-margin stretch-card">
            <div className="card profile-editor">
              <div className="card-body">
                <h4 className="card-title">Informations <span onClick={this.editInfos.bind(this)} title="Éditer" className={`edit-icon ${this.state.inEdition || !this.loaded ? 'disabled' : ''}`}><i className="mdi mdi-pencil" /></span></h4>
                <div className="row">
                    <div className="col avatar-editor">
                        {this.avatar()}
                        {this.avatarEditModal()}
                    </div>
                    <div className="col-9 u-infos-editor">
                    {this.state.inEdition ? this.userInfosEdition() : this.userInfosDefault() }
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>

    );
  }
}

const mapStateToProps = (state) => ({ app : state.app, users : state.users });

const UserInfos = connect(mapStateToProps, null)(userInfosComponent);
export default UserInfos;
