import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import * as moment from 'moment';
import React, { Component } from "react";
import { Form, Modal, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { updateSejoursEvents__action } from "../../../../actions/sejours.events.actions";
import AddEventModal from "../../../../components/pages/sejours/events/modals/add-modal";
import LoaderBox from "../../../../components/shared/loaders/loader.box";
import { apiRequest } from "../../../../functions/api";
import { pushNotification } from "../../../../functions/notifications";
import { getDeepState, setDeepState } from "../../../../functions/react.utilities";

class AddModal extends Component {
  constructor(props){
    super(props);
    this.state = this.getInitState();

    this.getDeepState = getDeepState(this);
    this.setDeepState = setDeepState(this);

    this.pState = () => ({ ...this.props.parentState });
    this.pSetState = this.props.parentSetState;
    this.pSetDeepState = this.props.parentSetDeepState;
    this.pGetDeepState = this.props.parentGetDeepState;

    this.pSetState = this.pSetState.bind(this);
    this.pSetDeepState = this.pSetDeepState.bind(this);
    this.pGetDeepState = this.pGetDeepState.bind(this);

    this.cancel = this.cancel.bind(this);
    this.createEvent = this.createEvent.bind(this);
    
  }

  getInitState(){
    return {
      loading : false,
      loadingMess : ""
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.show && (this.props.show !== prevProps.show)){
      this.setState(this.getInitState());
    }
  }

  createEvent(data){
    const state = this.getDeepState();
    state.loading = true;
    state.loadingMess = "Événement en cours d'ajout..."

    apiRequest({
      url : "/sejours.events/post",
      data : {
        sejourRelationID : data.model,
        startDate : data.startDate.format(),
        endDate : data.endDate.format(),
        ticketingStartDate : data.ticketingStartDate.format(),
        ticketingEndDate : data.ticketingEndDate.format(),
        nbRegistrantsMax : data.nbRegistrantsMax,
        guide : data.guide,
        carPoolingLink : data.carPoolingLink,
        prices : data.prices,
        visible : data.visible
      }
    })
    .then((res) => {
      const errorNotification = (m) => pushNotification({
        type : "error",
        title : "Ajout de l'événement impossible",
        icon : "mdi mdi-close",
        content : <p>{m}</p>
      });

      const state  = this.getDeepState();
      state.loading = false;

      if(res.data.http_code === 200){
        if(res.data.status === "success"){
          if(res.data.data && res.data.data.event){
            this.props.dispatch(updateSejoursEvents__action([res.data.data.event]));
            this.pSetDeepState((s) => s.modals.add = false);
            pushNotification({
              type : "success",
              title : "Événement ajouté",
              icon : "mdi mdi-plus",
              content : <p>L&apos;événement a été ajouté avec succès !</p>
            });
          }
        }else if(res.data.errors){
          errorNotification(`Erreur du serveur. Réessayez. (${res.data.status_code})`);
          console.error(res.data.errors);
        }else{
          errorNotification(`Erreur du serveur. Réessayez. (${res.data.status_code})`);
        }
      }else{
        errorNotification(`Erreur du serveur. Réessayez. (HTTP : ${res.data.http_code})`);
      }

      this.setState(state);
    })
    this.setState(state);
  }

  cancel(){
    this.pSetDeepState(s => s.modals.add = false);
  }

  render() {
    return (
      <AddEventModal 
        show={this.props.show}
        onCancel={this.cancel}
        onValid={this.createEvent}
        loading={this.state.loading}
        loadingMess={this.state.loadingMess}
      />
    );
  }
}

const mapStateToProps = (state) => ({ sejours : state.sejours });

export default connect(mapStateToProps, null)(AddModal);