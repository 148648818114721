import * as modelsActions from '../constants/sejours.models.actions';

export const getSejoursModels__action = (data) => {
    return {
        type : modelsActions.GET_SEJOURS_MODELS,
        payload : data
    }
};

export const deleteSejoursModels__action = (data) => {
    return {
        type : modelsActions.DELETE_SEJOURS_MODELS,
        payload : data
    }
};