import React, { Component } from 'react'
import { Redirect, withRouter } from 'react-router-dom'

class AuthRoute extends Component{
    renderingContent(){
        if(this.props.loggedIn){
            const C = this.props.component;
            return <C {...this.props} />
        }else{
            return <Redirect to={`/login?redirect=${this.props.location.pathname}${this.props.location.search}`} />
        }
    }
    render(){
        return this.renderingContent();
    }
}

export default withRouter(AuthRoute);