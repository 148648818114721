import React from 'react';
import { toast } from 'react-toastify';
import { isEmpty } from './common';

export function pushNotification({ type = "info", title, icon, content, theme = "colored", autoClose = 5000, ...config}){
    const classes = {
        success : 'text-success',
        error : 'text-error',
        warning : 'text-warning',
        info : 'text-primary'
    }

    const themes = ["light", "dark", "colored"];

    if(!(themes.indexOf(theme) >= 0)) theme = "colored";
    if(!classes[type]) type = "info";

    config.theme = theme;
    config.type = type;
    config.autoClose = autoClose;

    let haveTitle = !isEmpty(title);
    let haveIcon = !isEmpty(icon)
    let conf = {...config, icon : false }
    const getCorps = () => {
        const getTitle = () => {
            if(haveTitle){
                if(haveIcon){
                    return (
                        <div className={`toast-notif-title with-icon ${theme === "colored" ? "" : classes[type]}`}>
                            <i className={`toast-notif-icon ${icon}`}/>
                            <h6>{title}</h6>
                        </div>
                    )
                }else{
                    return (
                        <div className={`toast-notif-title ${theme === "colored" ? "" : classes[type]}`}>
                            <h6>{title}</h6>
                        </div>
                    )
                }
            }
        }
        const getContent = () => {
            if(haveIcon && !haveTitle){
                return(
                    <div className="toast-notif-content with-icon">
                        <div className="toast-notif-icon">
                            <i className={icon}/>
                        </div>
                        {content}
                    </div>
                )
            }else{
                return (
                    <div className="toast-notif-content">
                        {content}
                    </div>
                )
            }
        }

        return (
            <React.Fragment>
                {getTitle()}
                {getContent()}
            </React.Fragment>
        );
    }

    return toast(getCorps(), conf);
} 