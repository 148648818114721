import React, { Component } from 'react';
import { Button, Form, Row, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import CTabVertical from '../../../../../../components/custom-boostrap/CTab/CTabVertical';
import ConfigurationTab from './configuration-tab';
import RegistrantsTab from './registrants-tab';

class RegistrationsTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab : this.tabs.configuration
        }
    }
    

    tabs = {
        configuration : "configuration",
        registrants : "registrants"
    }

    render() {
        return (
            <CTabVertical variant="left" activeKey={this.state.tab} onSelect={(t) => this.setState({ tab : t })}>
                <Tab eventKey={this.tabs.registrants} title={<i className="mdi mdi-folder-multiple text-success"/>}>
                    <RegistrantsTab EID={this.props.EID} setLoader={this.props.setLoader}/>
                </Tab>
                <Tab eventKey={this.tabs.configuration} title={<i className="mdi mdi-settings text-warning"/>}>
                    <ConfigurationTab EID={this.props.EID} setLoader={this.props.setLoader}/>
                </Tab>
            </CTabVertical>
        );
    }
}

const mapStateToProps = (state) => ({ sejours : state.sejours });

export default connect(mapStateToProps, null)(RegistrationsTab);