import React, { Component } from "react";
import { Button, Form, Modal, Toast } from "react-bootstrap";
import { connect } from 'react-redux';
import * as apiCodes from "../../../constants/api/index";
import { apiRequest } from "../../../functions/api";
import { isEmpty } from "../../../functions/common";
import { pushNotification } from "../../../functions/notifications";
import { CForm } from "../../custom-boostrap/CForm";
import FieldsetCard from "../../custom-boostrap/Fieldeset/fieldset.card";
import LoaderBox from "../../shared/loaders/loader.box";
import PasswordConfirmModal from "../../shared/modals/password-confirm";

class userPrefsComponent extends Component{
    constructor(props) {
        super(props);
        this.defaultPasswordModal = {
            show : false,
            step : 1,
            isValid : false,
            isChanging : false,
            tokenAuth : null,
            newPassword : null,
            passwordConfirm : null,
            error : null,
            errorType : "invalid",
            loading : false,
            loadingMess : ""
        }
        this.state = {
            passwordModal : this.defaultPasswordModal
        }
    }
    setPasswordModal(s){
        this.setState({ passwordModal : { ...this.state.passwordModal, ...s }});
    }
    openPasswordModal(){
        this.setPasswordModal({...this.defaultPasswordModal, show : true });
    }
    changePassword(){
        if(this.state.passwordModal.isValid && this.state.passwordModal.tokenAuth){
            this.setPasswordModal({ 
                isChanging : true,
                loading : true, 
                loadingMess : "Modification du mot de passe" 
            });
            apiRequest({
                url : '/user.password.change/put',
                data : {
                    validationToken : this.state.passwordModal.tokenAuth.token,
                    newPassword : this.state.passwordModal.newPassword
                }
            })
            .then((res) => {
                if(res.data.http_code === 200){
                    if(res.data.status === "success" && res.data.data.passwordChanged){
                        this.setPasswordModal(this.defaultPasswordModal);
                        pushNotification({
                            title : "Mot de passe modifié",
                            icon : "mdi mdi-key",
                            content : <p>Votre mot de passe à été modifé avec succès !</p>,
                            type : "success",
                            autoClose : 5000,
                            closeOnClick : true,
                            draggable : true,
                            pauseOnHover : true
                        });
                    }else{
                        let err = null;
                        switch(res.data.status_code){
                            case apiCodes.INVALID_VALIDATION_TOKEN :
                            case apiCodes.EXPIRED_VALIDATION_TOKEN :
                                err = "Vous avez trop attendu pour choisir un nouveau mot de passe. Fermez et réessayez"
                                break;
                            default :
                                err = "Une erreur est survenue. Fermez et réessayez"
                                break;
                        }
                        this.setPasswordModal({ error : err , errorType : "invalid", loading : false })
                    }
                }else{
                    this.setPasswordModal({ error : "Erreur réseau. Réessayez.", errorType : "invalid", loading : false })
                }
            })
        }
    }
    passwordModal(){
        const cancel = () => {
            if(!this.state.passwordModal.isChanging) this.setPasswordModal({ step : 1, show : false });
        }
        const onChange = (prop, e) => {
            let state = { ...this.state.passwordModal, [prop] : e.target.value };
            let empty = (isEmpty(state.newPassword) || isEmpty(state.passwordConfirm));
            let different = state.newPassword !== state.passwordConfirm;
            state.isValid = !(empty || different);

            if(!empty && different) {
                state.error = "Les mots de passe ne correspondent pas";
            }else{
                state.error = null;
            }
            this.setPasswordModal(state);
        }
        return (
            <React.Fragment>
                <PasswordConfirmModal 
                    show={this.state.passwordModal.step == 1 && this.state.passwordModal.show}
                    onValid={function(data){ this.setPasswordModal({ step : 2, tokenAuth : data })}.bind(this)}
                    onDecline={cancel.bind(this)}
                />

                <Modal show={this.state.passwordModal.step == 2 && this.state.passwordModal.show} onHide={() => {}}>
                    <Modal.Header>Changer le mot de passe</Modal.Header>
                        <LoaderBox show={this.state.passwordModal.loading} mess={this.state.passwordModal.loadingMess}>
                            <Modal.Body>
                                <Form.Group className="form-group">
                                    <Form.Label>Nouveau mot de passe</Form.Label>
                                    <CForm.Control.Password disabled={this.state.passwordModal.isChanging} name="new-password" autoComplete="new-password" onChange={(e) => { onChange("newPassword", e) }} />
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>Confirmer le mot de passe</Form.Label>
                                    <CForm.Control.Password disabled={this.state.passwordModal.isChanging} name="confirm-new-password" onChange={(e) => { onChange("passwordConfirm", e) }}></CForm.Control.Password>
                                    {this.state.passwordModal.error ?
                                        <Form.Control.Feedback type={`${this.state.passwordModal.errorType}`}>{this.state.passwordModal.error}</Form.Control.Feedback>
                                    : null}
                                </Form.Group>
                            </Modal.Body>
                        </LoaderBox>
                    <Modal.Footer>
                        <Button variant="primary" disabled={!this.state.passwordModal.isValid || this.state.passwordModal.isChanging} onClick={this.changePassword.bind(this)}>Valider</Button>
                        <Button variant="secondary" onClick={cancel.bind(this)} disabled={this.state.passwordModal.isChanging}>Annuler</Button>
                    </Modal.Footer>
                </Modal>
                
            </React.Fragment>

        )
    }
    render() {
        return (
        <div className="row">
            <div className="col grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                <h4 className="card-title">Préférences </h4>
                <div className="row">

                    <div className="col u-infos-editor">
                        <FieldsetCard fieldsetTitle="Mot de passe">
                            <p>Pour assurer la sécurité de votre compte, changez votre mot de passe régulièrement.</p>
                            <Button size="sm" onClick={this.openPasswordModal.bind(this)}>Changer</Button>
                            {this.passwordModal()}
                        </FieldsetCard>
                        <FieldsetCard fieldsetTitle="Suppresion de compte">
                            <p>Si vous ne vous servez plus de votre compte, vous pouvez choisir de le supprimer. Cette action sera irréversible.</p>
                            <Button variant="danger" size="sm" onClick={() => {}} disabled title="Option indisponible">Supprimer</Button>
                        </FieldsetCard>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        );
    }
}
const mapStateToProps = (state) => ({ user : state.user });
const userPrefs = connect(mapStateToProps, null)(userPrefsComponent);

export default userPrefs;

