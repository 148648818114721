import React from "react";
import * as usersActions from "../constants/users.actions";



export const setUsers__action = (users) => {
    return {
        type : usersActions.SET_USERS,
        payload : {
            users : users
        }
    }
}

export const updateUsers__action = (users) => {
    return { 
            type : usersActions.UPDATE_USERS, 
            payload : {
                users : users
            }
        }
}

export const deleteUsers__action = (UIDS = []) => {
    return { 
            type : usersActions.DELETE_USERS, 
            payload : {
                UIDS : UIDS
            }
        }
}