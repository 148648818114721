import * as sejoursGlobalsActions from '../../../constants/sejours.globals.actions';

export default function globals(state, action){
    const n_state = { ...state };
    switch(action.type){
        case sejoursGlobalsActions.GET_SEJOURS_MODELS_EVENTS_PRICES:
            return { ...state, ...action.payload };

        case sejoursGlobalsActions.UPDATE_SEJOURS_MODELS_EVENTS_PRICES:
            n_state.models = { ...n_state.models, ...action.payload.models };
            n_state.events = { ...n_state.events, ...action.payload.events };
            n_state.prices = { ...n_state.prices, ...action.payload.prices };
            
            return n_state;

        case sejoursGlobalsActions.DELETE_SEJOURS_MODELS_EVENTS_PRICES:
            if(action.payload.models && n_state.models){
                action.payload.models.forEach((m) => {
                    delete n_state.models[m]
                });
            }

            if(action.payload.events && n_state.events){
                action.payload.events.forEach((e) => {
                    delete n_state.events[e]
                });
            }

            if(action.payload.prices){
                action.payload.prices.forEach((p) => {
                    delete n_state.prices[p]
                });
            }

            return n_state;

        default:
            return state;
    }
}