import * as sejoursModelsActions from '../../../constants/sejours.models.actions';

export default function models(state, action){
    switch(action.type){
        case sejoursModelsActions.GET_SEJOURS_MODELS:
            return { ...state, models : { ...state.models, ...action.payload } }
            
        default:
            return state;
    }
}