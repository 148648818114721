import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './styles/index.scss'
import { connect } from 'react-redux';

import { noLoadingScreenRoutes, likeSamePathRoutes } from './components/Routes/routes';

import { ToastContainer } from 'react-toastify';

import Routes from './components/Routes';
import Navbar from './components/shared/Navbar';
import Sidebar from './components/shared/Sidebar';
import Footer from './components/shared/Footer';
import { getUser } from './actions/app.actions';
import { setPageTitle } from './functions/common';
import { pushNotification } from './functions/notifications';



class AppComponent extends Component {
  constructor (props){
    super(props);
    this.state = {
      isFullPageLayout : false
    }

    this.loader = document.getElementById('loading-react');
    this.lastPage = props.location;

    this.loadingTimeFreq = 50;
    this.loadingTimeMin = 1000;
    this.loading = {
      isLoading : true,
      screen : false,
      screenTimeout : null,
      screenDisplayed : true,
      isFirst : true,
      isRealFirst : true,
      time : 0,
      timer : setInterval(this.timeLoading.bind(this), this.loadingTimeFreq)
    }

    this.loadingActionsEnded = [];

  }

  timeLoading(){ 
    this.loading.time += this.loadingTimeFreq;
  }
  
  setLoadingScreenTimeout(timeout){
    if(this.loading.screenTimeout) clearTimeout(this.loading.screenTimeout);
    this.loading.screenTimeout = timeout;
  }

  hideLoader = (force = false) => {
    const setHiddenLoader = () => {
      this.loader.setAttribute('hidden', '');
      this.loader.removeEventListener('transitionend', setHiddenLoader)
      document.querySelector('html').classList.remove('app-is-loading');
      document.querySelector('body').classList.remove('app-is-loading');
    }
    const hide = () => {
      this.loading.screenDisplayed = false;
      this.loader.removeAttribute('loading');
      this.loader.addEventListener('transitionend', setHiddenLoader);
      this.forceUpdate();
    }

    if(this.loading.time < this.loadingTimeMin && !force){
      clearInterval(this.loading.timer);
      this.setLoadingScreenTimeout(setTimeout(hide, this.loadingTimeMin - this.loading.time));
    }else{
      hide();
    }
  }

  showLoader = () => {
    clearTimeout(this.loading.screenTimeout);
    this.loading.screenDisplayed = true;
    setPageTitle('Chargment en cours');
    document.querySelector('html').classList.add('app-is-loading');
    document.querySelector('body').classList.add('app-is-loading');
    this.loader.setAttribute('loading', '');
    this.loader.removeAttribute('hidden');
  }

  registerLoadingAction(func, ...params){
    let actionResult = func.apply(params);
    let resultIndex = this.loadingActionsEnded.length;
    if(!this.loading.screenDisplayed && this.loading.isFirst && this.loading.screen){
      this.showLoader();
    }
    if(!this.loading.isLoading){
      this.loading.isLoading = true;
    }

    if(actionResult instanceof Promise){
      this.loadingActionsEnded.push(false);
      let resolver = () => { this.resolveLoadingAction.apply(this, [resultIndex]) };
      actionResult.finally(resolver.bind(this));
    }else{
      this.resolveLoadingAction(resultIndex);
    }
  }

  resolveLoadingAction(i){
    const resolveLoading  = () => {
      this.hideLoader();
      this.loading.isLoading = false;
    }
    this.loadingActionsEnded[i] = true;
    if(this.loadingActionsEnded.indexOf(false) < 0){
      this.loadingActionsEnded = [];
      resolveLoading();
    }
  };

  setLoadingScreen(){
    if (this.props.location !== this.lastPage) {
      if(noLoadingScreenRoutes.indexOf(this.lastPage.pathname) >= 0 && noLoadingScreenRoutes.indexOf(this.props.location.pathname) < 0){
        if(this.loading.isFirst && !this.loading.screenDisplayed){
          this.showLoader();
          this.setLoadingScreenTimeout(setTimeout(this.hideLoader.bind(this), this.loadingTimeMin));
        }
        this.loading.isFirst = false;
      }
      this.loading.isRealFirst = false;
    }else{
      if (!(noLoadingScreenRoutes.indexOf(this.props.location.pathname) >= 0)){
        this.loading.isFirst = false;
      }
    }

    if(this.loading.screen){
      if(!this.loading.isLoading){
        if(this.loading.isRealFirst && this.loading.isFirst){
          this.hideLoader();
        }
      }else{
        if(this.loading.isRealFirst && this.loading.isFirst){
          if(!this.loading.isLoading && this.loading.screenDisplayed){
            this.showLoader();
            this.setLoadingScreenTimeout(setTimeout(this.hideLoader.bind(this), this.loadingTimeMin));
          }
        }
      }
    }else{
      this.hideLoader(true);
    }
  }

  componentDidMount () {
    this.onRouteChanged();
  }  

  componentDidUpdate(prevProps) {
    const currentLocationURL = `${this.props.location.pathname}${this.props.location.search}`;
    const prevLocationURL = `${prevProps.location.pathname}${prevProps.location.search}`;

    const samePath = currentLocationURL === prevLocationURL;
    let likeSamePath = samePath;
    likeSamePathRoutes.forEach((r) => {
      if(currentLocationURL.indexOf(r) >= 0 && prevLocationURL.indexOf(r) >= 0){
        likeSamePath = true;
      }
    });
    if(!samePath){
      this.lastPage = prevProps.location;
      this.loading.isRealFirst = false;
    }
    if(!likeSamePath){
      this.onRouteChanged();
    }
  }

  updateUser(){
    const wasLoggedIn = this.props.storage.app.loggedIn;
    let p = getUser(this.props.dispatch);
    p.then((res) => {
      if(res){
        if(res.data.http_code !== 200){
          if(wasLoggedIn){
            pushNotification({
              type : "info",
              icon : "mdi mdi-logout",
              content : <p>Vous avez été déconnecté en raison de votre inactivité.</p>
            });
          }
        }
      }
    })
    return p;
  }

  onRouteChanged() {
    //To enforce style
    document.body.classList.add('s-enforcer');

    window.scrollTo(0, 0);

    let isFullPageLayout = false;
    for ( let i = 0; i < noLoadingScreenRoutes.length; i++ ) {
      if (this.props.location.pathname === noLoadingScreenRoutes[i]) {
        isFullPageLayout = true;
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
    this.loading.screen = true;
    for (let i = 0; i < noLoadingScreenRoutes.length; i++ ) {
      if (this.props.location.pathname === noLoadingScreenRoutes[i]) {
        this.loading.screen = false;
        break;
      }
    }

    this.setState({ isFullPageLayout }) 
    this.registerLoadingAction(this.updateUser.bind(this));

    this.setLoadingScreen();
  }

  getRoutes(){
    if(!this.loading.screenDisplayed){
      return (<Routes loggedIn={this.props.storage.app.loggedIn} />)
    }
  }
  render() {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar/> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : '';

    return (
        <React.Fragment>
            <ToastContainer icon={false} position="top-right"/>
            <div className="container-scroller">
            { sidebarComponent }
            <div className="container-fluid page-body-wrapper">
              { navbarComponent }
              <div className="main-panel">
                <div className="content-wrapper">
                    {this.getRoutes()}
                </div>
                { footerComponent }
              </div>
            </div>
          </div>
      </React.Fragment>
    )
  };
}

const mapStateToProps = (state) =>  ({ storage : state });


const App = connect(mapStateToProps)(AppComponent);
export default withRouter(App);

