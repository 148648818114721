import React, { Component } from 'react'
import { Badge, Button, Form } from 'react-bootstrap';
import LoaderBox from '../../../shared/loaders/loader.box';
import { PAYMENT_FAILED_STATUS, PAYMENT_PENDING_STATUS, PAYMENT_VALIDATED_STATUS } from '../../../../constants/api/payments';
import { apiRequest } from "../../../../functions/api";
import moment from 'moment';
import ReactJson from 'react-json-view';
import { pushNotification } from '../../../../functions/notifications';
import { isEmpty } from '../../../../functions/common';

class RegistrationsPaymentsSearcher extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onValid = this.onValid.bind(this);

        this.state = {
            payementRef: "",
            loading: false,
            loadingMess: "Recherche en cours",
            paymentData: {},
            results: -1
        };
    }

    static statusBadgeConfig = {
        [PAYMENT_FAILED_STATUS]: {text: "Échoué", color: 'danger'},
        [PAYMENT_PENDING_STATUS]: {text: "En attente", color: 'warning'},
        [PAYMENT_VALIDATED_STATUS]: {text: "Validé", color: 'success'}
    };

    getReference() {
        this.setState({
            loading: true,
            loadingMess: "Recherche en cours"
        });
        apiRequest({
            url : '/payments.reference/get',
            data : {
                reference : this.state.payementRef
            }
        })
        .then((res) => {
            const newState = {...this.state, loading : false};
            if (res.data.http_code === 200 && res.data.status === "success") {
                newState.results = res.data.data.results;
                newState.paymentData = res.data.data.paymentData;
            } else {
                pushNotification({
                    type: "error",
                    title: "Erreur du serveur",
                    content: <p>Une erreur inconnue est survenue. Veuillez réessayer.</p>,
                    icon: "mdi mdi-close"
                });
            }
            this.setState(newState);
        })
    }

    onChange(e) {
        this.setState({ payementRef : e.target.value });
    }

    onValid(e) {
        e.preventDefault();
        if (!isEmpty(this.state.payementRef))
            this.getReference();
    }

    getBadge(status) {
        let config = RegistrationsPaymentsSearcher.statusBadgeConfig[status];

        if (!config)
            config = RegistrationsPaymentsSearcher.statusBadgeConfig[PAYMENT_FAILED_STATUS];
        return <Badge bg={config.color}>{config.text}</Badge>
    }

    renderPayer(data) {
        return (<div className='card card-second mt-4 registrations-payment-payer'>
            <div className="card-body">
                <h5><i className='mdi mdi-account-circle'/>Identité du payeur</h5>
                <div className="row">
                    <div className="col-xl-3 col-sm-6">
                        <b>Prénom :</b> {data.payer.firstName}
                    </div>
                    <div className="col-xl-3 col-sm-6">
                        <b>Nom :</b> {data.payer.name}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-xl-3 col-sm-6">
                        <b>Téléphone :</b> <a className='no-link' href={`tel:+33${data.payer.phone}`}>{data.payer.phone}</a>
                    </div>
                    <div className="col-xl-3 col-sm-6">
                        <b>Email :</b> <a className='no-link' href={`mailto:${data.payer.email}`}>{data.payer.email}</a>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-xl-3 col-sm-6">
                        <b>Adresse :</b>
                        <address>
                            <p>{data.payer.address}</p>
                            <p>{data.payer.postalCode} - {data.payer.city}</p>
                            <p>{data.payer.country}</p>
                        </address>
                    </div>
                    <div className="col-xl-3 col-sm-6">
                        <b>Inscription associée : </b>
                        {data.associatedRFID ?
                            <a target="_blank" href={`${process.env.REACT_APP_WWW_URL}/inscriptions/status/${data.associatedRFID}`}>{data.associatedRFID}</a>
                            :
                            <span className='text-muted'>aucune</span>
                        }
                    </div>
                </div>
            </div>
        </div>)
    }

    renderDetails(data) {
        return (<div className='card card-second mt-4 registrations-payment-details'>
            <div className="card-body">
                <h4 className="card-title mb-3">Attestation de paiement</h4>
                <h5>Données brutes</h5>
                <div className="details">
                    <ReactJson
                        src={data.details}
                        collapsed={true}
                        theme="rjv-default"
                        name={null}
                        enableClipboard={false}
                        displayDataTypes={false}
                        displayObjectSize={false}

                    />
                </div>
            </div>
        </div>)
    }

    renderPaymentData(data) {
        const amount = data.amount / 100;
        const amountText = parseFloat(amount).toFixed(2) > 0 ? `${parseFloat(amount).toFixed(2)}€` : "Gratuit";
        const initDate = moment(data.initDate);
        const canceledDate = moment(data.canceledDate);
        const paidDate = moment(data.paidDate);
        return (
            <div className="row">
                    <div className="col grid-margin stretch-card">
                            <div className="card profile-editor">
                                <div className="card-body">
                                    <h4 className="card-title mb-1">Détails du paiement</h4>
                                    <div className='registrations-payement-infos'>
                                        <div>
                                            <p className="text-muted text-center m-0">
                                                <i className="mdi mdi-sync-circle text-primary" aria-hidden="true" />&nbsp;
                                                {data.nbTries} {data.nbTries === 1 ? 'essai' : 'essais'}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="text-muted text-center m-0">
                                                <i className="mdi mdi-currency-usd text-success" aria-hidden="true" />&nbsp;{amountText}
                                            </p>
                                        </div>
                                        <div>
                                            {this.getBadge(data.status)}
                                        </div>
                                    </div>
                                    <p className="text-muted mb-1">Référence : {data.ref}</p>
                                    <div className='registrations-payment-chronology'>
                                    <p className="mb-1"><span className="text-muted">Initié le :</span> {initDate.format("dddd DD MMMM YYYY à HH:mm:ss")}</p>
                                        {data.status === PAYMENT_VALIDATED_STATUS ?
                                            <p className="mb-1"><span className="text-muted">Payé le :</span> {paidDate.format("dddd DD MMMM YYYY à HH:mm:ss")}</p>
                                        :
                                            null
                                        }
                                        {data.status === PAYMENT_FAILED_STATUS ?
                                            <p className="mb-1"><span className="text-muted">Annulé le :</span> {canceledDate.format("dddd DD MMMM YYYY à HH:mm:ss")}</p>
                                        :
                                            null
                                        }
                                    </div>
                                    {this.renderPayer(data)}
                                    {this.renderDetails(data)}
                                </div>
                            </div>
                    </div>
            </div>
        );
    }

    renderNotFound() {
        return (
            <div className="row">
                    <div className="col grid-margin stretch-card">
                            <div className="card profile-editor">
                                <div className="card-body">
                                    <p className='text-center'>Aucun résultat pour cette référence</p>
                                </div>
                            </div>
                    </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col grid-margin stretch-card">
                        <div className="card profile-editor">
                            <LoaderBox show={this.state.loading} mess={this.state.loadingMess} className="card" inline>
                                <div className="card-body">
                                    <h4 className="card-title">Rechercher</h4>
                                    <Form onSubmit={this.onValid}>
                                        <Form.Group className="form-group">
                                            <Form.Label>Rechercher un paiement par sa référence</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Référence du paiement"
                                                name="paymentRef"
                                                value={this.state.payementRef}
                                                onChange={this.onChange}
                                                autoComplete="off" 
                                            />
                                        </Form.Group>
                                        {this.state.results === 0 ?
                                            <Form.Control.Feedback type="invalid">Aucun résultat pour cette référence</Form.Control.Feedback>
                                        :
                                            null
                                        }
                                        <div className="btn-container-inline-right mb-0">
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={!this.state.payementRef || this.state.loading}
                                                onClick={this.onValid}
                                            >
                                                    <i className="mdi mdi-magnify"/>Rechercher
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </LoaderBox>
                        </div>
                        
                    </div>
                </div>
                { this.state.results >= 1 ? this.renderPaymentData(this.state.paymentData) : null }
            </div>
        );
    };
}

export default RegistrationsPaymentsSearcher;
