import React from 'react';
import { Menu, Item, Separator, Submenu, animation, useContextMenu } from "react-contexify";

const ContextMenuMenu = (props) => {
    const { show } = useContextMenu();
    
    const displayMenu = (e) => {
        show(e, {
            id : props.id 
        })
    }

    const CMenu = (p) => {
        return (
            <Menu id={props.id} animation={animation.fade}>
                {p.children}
            </Menu>
        )
    }

    return { show : displayMenu, Menu : CMenu}
}
const ContextMenuItem = ({ children, ...props }) => {
    return (<Item {...props}>{children}</Item>)
}
const ContextMenuSeparator = (props) => {
    return (<Separator {...props}/>)
}

const ContextMenuSubmenu = ({ children, ...props }) => { 
    return (<Submenu {...props}>
        {children}
    </Submenu>)
}


const ContextMenu = {
    Menu : ContextMenuMenu,
    Item : ContextMenuItem,
    Separator : ContextMenuSeparator,
    Submenu : ContextMenuSubmenu
}

export default ContextMenu;