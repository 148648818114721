export const NO_SIDS = "NO_SIDS";
export const INVALID_SIDS = "INVALID_SIDS";
export const NO_SID = "NO_SID";
export const INVALID_SID = "INVALID_SID";
export const ALREADY_NAME = "ALREADY_NAME";


//Gestion des events
export const NO_EID = "NO_EID";
export const NO_SEJOUR_RELATION_ID = "NO_SEJOUR_RELATION_ID";
export const START_DATE_OMITTED = "START_DATE_OMITTED";
export const END_DATE_OMITTED = "END_DATE_OMITTED";
export const END_DATE_BEFORE_START = "END_DATE_BEFORE_START";
export const START_DATE_TICKETING_AFTER_START = "START_DATE_TICKETING_AFTER_START";
export const END_DATE_TICKETING_AFTER_START = "END_DATE_TICKETING_AFTER_START";



//Gestion des prices
export const NO_PIDS = "NO_PIDS";
export const INVALID_PIDS = "INVALID_PIDS";
export const NO_PID = "NO_PID";
export const INVALID_PID = "INVALID_PID";
export const NO_SEJOUR_RELATION_IDS = "NO_SEJOUR_RELATION_IDS";
export const INVALID_RELATION_TYPE = "INVALID_RELATION_TYPE";
export const ALREADY_LABEL = "ALREADY_LABEL";
export const NO_LABEL = "NO_LABEL";
export const NO_AMOUNT = "NO_AMOUNT";

export const MODEL_RELATION_TYPE = "MODEL";
export const GLOBAL_RELATION_TYPE = "GLOBAL";