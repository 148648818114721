import React, { Component } from "react";
import { connect } from "react-redux";
import { updateSejoursModelsEventsPrices__action } from "../../../../../../actions/sejours.globals.actions";
import { updateSejoursPrices__action } from "../../../../../../actions/sejours.prices.actions";
import EditAddPriceModal from "../../../../../../components/pages/sejours/prices/modals/edit-add-modal";
import { EMPTY } from "../../../../../../constants/api";
import { ALREADY_LABEL, MODEL_RELATION_TYPE } from "../../../../../../constants/api/sejours";
import { apiRequest } from "../../../../../../functions/api";
import { isEmpty } from "../../../../../../functions/common";
import { pushNotification } from "../../../../../../functions/notifications";
import { getDeepState, setDeepState } from "../../../../../../functions/react.utilities";

class EditAddModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            loadingMess : "",
            errors : {
                label : "",
                description : "",
                amount : ""
            }
        }

        this.getDeepState = getDeepState(this);
        this.setDeepState = setDeepState(this);

        this.pState = () => ({ ...this.props.parentState });
        this.pSetState = this.props.parentSetState;
        this.pSetDeepState = this.props.parentSetDeepState;
        this.pGetDeepState = this.props.parentGetDeepState;

        this.pSetState = this.pSetState.bind(this);
        this.pSetDeepState = this.pSetDeepState.bind(this);
        this.pGetDeepState = this.pGetDeepState.bind(this);

        this.onCancel = this.onCancel.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onEdit = this.onEdit.bind(this);
    }

    componentDidUpdate(prevProps){
        if(this.props.show && (this.props.show !== prevProps.show)){
          this.setState({ loading : false, loadingMess : "" }); 
        }
      }

    getErrors(){
        const errors = {};
        Object.entries(this.state.errors).forEach(([prop, value]) => {
            if(!isEmpty(value)){
                errors[prop] = value;
            }
        });
        return errors;
    }

    getClearedErrors(){
        const errors = { ...this.state.errors }
        Object.keys(errors).forEach(k => {
            errors[k] = "";
        });
        return errors;
    }


    onCancel(){
        this.pSetDeepState(s => { s.modals.editAdd = false });
        this.setDeepState(s => { s.loading = false });
    }

    onCreate({ label, description, amount }){
        const newState = this.getDeepState();
        newState.loading = true;
        newState.loadingMess = "Ajout en cours...";

        if(isEmpty(amount) || isNaN(amount)){
            amount = 0;
        }else{
            amount = parseFloat(amount) * 100;
        }

        apiRequest({
            url : '/sejours.prices/post',
            data : {
                sejourRelationIDS : this.props.SID,
	            sejourRelationType : MODEL_RELATION_TYPE,
	            label,
	            amount,
                description
            }
        })
        .then((res) => {
            const errorNotification = (m) => pushNotification({
                type : "error",
                title : "Impossible d'ajouter le tarif au modèle",
                icon : "mdi mdi-close",
                content : <p>{m}</p>
            });

            const newState = this.getDeepState();
            newState.loading = false;

            if(res.data.http_code === 200){
                if(res.data.status === "success"){
                    //Vide les erreurs
                    newState.errors = this.getClearedErrors();
                    if(res.data.data && res.data.data.price){
                        let models = { ...this.props.sejours.models };
                        Object.entries(res.data.data.models).forEach(([SID, model]) => {
                            if(SID in models){
                                models[SID].prices = model.prices;
                                models[SID].pricesConfig = model.pricesConfig;
                                models[SID].pricesDefaultConfig = model.pricesDefaultConfig
                            }else{
                                models[SID] = model;
                            }
                        });
                        this.props.dispatch(updateSejoursModelsEventsPrices__action({
                            models,
                            prices : { [res.data.data.price.PID] : res.data.data.price }
                        }));
                        pushNotification({
                            type : "success",
                            title : "Tarif ajouté au modèle",
                            icon : "mdi mdi-currency-eur",
                            content : <p>Le tarif a été ajouté au modèle. Il est réservé aux événements de ce modèle uniquement.</p>
                        });
                        this.pSetDeepState(s => { 
                            s.modals.editAdd = false 
                            s.configs[res.data.data.price.PID] = { 
                                ...res.data.data.models[this.props.SID].pricesDefaultConfig[res.data.data.price.PID],
                                ...res.data.data.models[this.props.SID].pricesConfig[res.data.data.price.PID]
                            }
                        });
                    }else{
                        errorNotification("Une erreur est survenue. Veuillez réessayer.");
                    }
                }else{
                    if(res.data.errors){
                        Object.entries(res.data.errors).forEach(([prop, value]) => {
                            switch(prop){
                                case 'label':
                                    switch(value){
                                        case EMPTY:
                                            newState.errors.label = "Entrez un libellé";
                                            break;
                                        case ALREADY_LABEL:
                                            newState.errors.label = "Libellé déjà utilisé";
                                            break;
                                        default:
                                            newState.errors.label = "Libellé invalide";
                                            break;
                                    }
                                    break;
                            
                                default:
                                    errorNotification("Une erreur est survenue. Veuillez réessayer.");
                                    break;
                            }
                        });
                    }else{
                        errorNotification(`Erreur du serveur (${res.data.status_code})`);
                    }
                }
            }else{
                errorNotification(`Erreur du serveur (${res.data.http_code})`);
            }
            this.setState(newState);
        })

        this.setState(newState);
    }

    onEdit({ label, description, amount }){
        const newState = this.getDeepState();
        newState.loading = true;
        newState.loadingMess = "Enregistrement en cours...";

        if(isEmpty(amount) || isNaN(amount)){
            amount = 0;
        }else{
            amount = parseFloat(amount) * 100;
        }

        apiRequest({
            url : '/sejours.prices/put',
            data : {
                PID : this.props.PID,
                price : {
                    label,
                    amount,
                    description
                }
            }
        })
        .then((res) => {
            const errorNotification = (m) => pushNotification({
                type : "error",
                title : "Impossible de mettre à jour le tarif",
                icon : "mdi mdi-close",
                content : <p>{m}</p>
            });

            const newState = this.getDeepState();
            newState.loading = false;

            if(res.data.http_code === 200){
                if(res.data.status === "success"){
                    //Vide les erreurs
                    newState.errors = this.getClearedErrors();
                    if(res.data.data && res.data.data.price){
                        this.props.dispatch(updateSejoursPrices__action([res.data.data.price]));
                        pushNotification({
                            type : "success",
                            title : "Modification enregistrées !",
                            icon : "mdi mdi-currency-eur",
                            content : <p>Les modifications apportées ont été enregistrées.</p>
                        });
                        this.pSetDeepState(s => { s.modals.editAdd = false });
                    }else{
                        errorNotification("Une erreur est survenue. Veuillez réessayer.");
                    }
                }else{
                    if(res.data.errors){
                        Object.entries(res.data.errors).forEach(([prop, value]) => {
                            switch(prop){
                                case 'label':
                                    switch(value){
                                        case EMPTY:
                                            newState.errors.label = "Entrez un libellé";
                                            break;
                                        case ALREADY_LABEL:
                                            newState.errors.label = "Libellé déjà utilisé";
                                            break;
                                        default:
                                            newState.errors.label = "Libellé invalide";
                                            break;
                                    }
                                    break;
                            
                                default:
                                    errorNotification("Une erreur est survenue. Veuillez réessayer.");
                                    break;
                            }
                        });
                    }else{
                        errorNotification(`Erreur du serveur (${res.data.status_code})`);
                    }
                }
            }else{
                errorNotification(`Erreur du serveur (${res.data.http_code})`);
            }
            this.setState(newState);
        })

        this.setState(newState);
    }

    getProps(){
        const props = {}
        if(this.props.mode === EditAddPriceModal.modes.edit){
            props.title = "Editer un tarif";
            props.onValid = this.onEdit;
            props.data = {}
            if(this.props.PID){
                const price = this.props.sejours.prices[this.props.PID];
                if(price){
                    props.data = {
                        label : price.label,
                        amount : (price.amount / 100).toString(),
                        description : price.description
                    }
                }
            }
        }else{
            props.title = "Ajouter un tarif pour le modèle";
            props.onValid = this.onCreate;
            props.data = {}
        }

        return props;
    }
    render(){
        const { title, onValid, data } = this.getProps();
        return (<EditAddPriceModal 
            mode={this.props.mode}
            data={data}
            modalTitle={title}
            show={this.props.show}
            errors={this.getErrors()}
            loading={this.state.loading}
            loadingMess={this.state.loadingMess}
            onValid={onValid}
            onCancel={this.onCancel}
        />)
    }

}

const mapStateToProps = (state) => ({ sejours : state.sejours });

export default connect(mapStateToProps, null)(EditAddModal);