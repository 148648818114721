import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthRoute from './authRoute';

// Auth components
import Dashboard from '../../pages/dashboard';
import Profil from '../../pages/profil';
import Logout from '../../pages/logout';
import SejoursModelsRouter from '../../pages/sejours/models/router';
import SejoursEventsRouter from '../../pages/sejours/events/router';
import SejoursPrices from '../../pages/sejours/prices';
import RegistrationsRegistrants from '../../pages/registrations/registrants';
import RegistrationsPayments from '../../pages/registrations/payments';

//Public components
import Login from '../../pages/login';
import ResetPassword from '../../pages/reset-password';
import AuthRedirect from './authRedirect';
import OtherUsers from '../../pages/others-users';




class Routes extends Component {    
    render(){
      const loggedIn = this.props.loggedIn;
      return (
        <Switch>
          {/* AuthRoutes */}
          <AuthRoute exact loggedIn={loggedIn} path="/dashboard" component={ Dashboard } />
          <AuthRoute loggedIn={loggedIn} path="/logout" component={ Logout } />
          <AuthRoute loggedIn={loggedIn} path="/profil" component={ Profil } />
          <AuthRoute loggedIn={loggedIn} path="/other-users" component={ OtherUsers } />
          <AuthRoute loggedIn={loggedIn} path="/sejours/models" component={ SejoursModelsRouter } />
          <AuthRoute loggedIn={loggedIn} path="/sejours/events" component={ SejoursEventsRouter } />
          <AuthRoute loggedIn={loggedIn} path="/sejours/prices" component={ SejoursPrices } />
          <AuthRoute loggedIn={loggedIn} path="/registrations/registrants" component={ RegistrationsRegistrants } />
          <AuthRoute loggedIn={loggedIn} path="/registrations/payments" component={ RegistrationsPayments } />

          {/* PublicRoutes */}
          <Route path="/login" component={ Login } />
          <Route path="/reset-password" component={ ResetPassword } />
            
          {/* Auth or No Auth Redirection */}
          <AuthRedirect loggedIn={loggedIn} authTo="/dashboard"  noAuthTo="/login"/>
        </Switch>
      );
    }
}

export default Routes;