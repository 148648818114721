import React, { Component } from 'react';
import UserInfos from '../components/pages/profil/user.infos';
import UserPrefs from '../components/pages/profil/user.prefs';
import UserGuide from '../components/pages/profil/user.guide';
import { setPageTitle } from '../functions/common';

class ProfilComponent extends Component{
    constructor(props) {
        super(props);
        setPageTitle("Mon profil");
    }
    

    render(){
        return(
            <div>
                <h2>Mon profil</h2>
                <UserInfos/>
                <UserGuide/>
                <UserPrefs/>
            </div>
        )
    }
}

export default ProfilComponent;