import React, { Component } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { MODEL_RELATION_TYPE } from '../../../../constants/api/sejours';
import { lengthObject } from '../../../../functions/common';

class PricesSelector extends Component {
    constructor(props) {
        super(props);
        this.selectPriceRef = React.createRef();

        this.onAdd = this.onAdd.bind(this);
    }
    
    onAdd(e){
        e.preventDefault();
        if(this.props.onAdd){
            this.props.onAdd(this.selectPriceRef.current.value);
        }
    }
    
    optionsRender(){
        if(lengthObject(this.props.prices) > 0){
            return Object.entries(this.props.prices).map(([PID, price]) => {
                return (<option value={PID} key={PID}>
                {price.sejourRelationType === MODEL_RELATION_TYPE ? "M" : "G"}
                &nbsp;-&nbsp;
                {price.label}
                &nbsp;-&nbsp;
                {parseFloat(price.amount / 100).toFixed(2) > 0 ? `${parseFloat(price.amount / 100).toFixed(2)}€` : "Gratuit"}
                </option>)
            })
        }else{
            return <option value="empty">Pas de tarifs disponibles</option>
        }
    }

    render() {
        return (
            <InputGroup className={this.props.className}>
                <Form.Select ref={this.selectPriceRef} className={`form-control${this.props.classNameSelect ? ` ${this.props.classNameSelect}` : ""}`} disabled={!(lengthObject(this.props.prices) > 0) || this.props.selectDisabled || this.props.disabled}>
                    { this.optionsRender() }
                </Form.Select>
                <Button variant="primary" disabled={!(lengthObject(this.props.prices) > 0) || this.props.buttonDisabled || this.props.disabled} onClick={this.onAdd}><i className="mdi mdi-plus"/>Ajouter</Button>
            </InputGroup>
        );
    }
}

export default PricesSelector;