import React, { useEffect, useState } from "react";
import { useTable, useSortBy, useRowSelect } from 'react-table';
import ContextMenu from '../../shared/context-menu/index';
const InderterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <div className="form-check form-check-muted m-0">
                <label className="form-check-label">
                    <input type="checkbox" className="form-check-input" ref={resolvedRef} {...rest}/>
                        <i className="input-helper" />
                </label>
            </div>
        )
    }
);
const CellRender = (props) => {
    const id = props.column.id;
    const value = props.value;

    switch(id){
        case 'email':
            return (<a href={`mailto:${value}`} target="blank">{value}</a>);

        case 'phone':
            return (<a href={`tel:${value}`}>{value}</a>);

        case 'profilImage':
            return (<img src={value} alt=""/>);
        
        case 'guide':
            return (
                <span>
                    {value ? <i className="mdi mdi-check" /> : <i className="mdi mdi-close" />}
                    {value ? " Oui" : " Non"}
                </span>
            );
        default:
            return value;
    }
}
const HeaderRender = (headerName = null, icon = true) => {
    if(headerName){
        return (
            <React.Fragment>
                {headerName}
                {icon ? <i className="mdi mdi-sort" /> : "" }
            </React.Fragment>   
        )
    }else{
        return ""
    }
}
const UsersTable = (props) => {
    const columnsConfig = [
        { Header : (p) => HeaderRender("Profil", false), Cell: CellRender, accessor : "profilImage", disableSortBy: true},
        { Header : (p) => HeaderRender("Nom"), Cell: CellRender, accessor : "name"},
        { Header : (p) => HeaderRender("Prénom"), Cell: CellRender, accessor : "firstName" },
        { Header : (p) => HeaderRender("Accompagnateur"), Cell: CellRender, accessor : "guide" },
        { Header : (p) => HeaderRender("Email"), Cell: CellRender, accessor : "email" },
        { Header : (p) => HeaderRender("Téléphone"), Cell: CellRender, accessor : "phone" }
    ];

    const [columns, setColumns] = useState(columnsConfig);

    const selection = props.selection ? props.selection : {};

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        state : { selectedRowIds }
      } = useTable({ columns, data : props.data, initialState : { selectedRowIds : selection} }, useSortBy, useRowSelect, 
        hooks => {
            hooks.visibleColumns.push(
                columns => [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                          <div>
                            <InderterminateCheckbox {...getToggleAllRowsSelectedProps({ title : "Tout sélectionner "})} />
                          </div>
                        ),
                        Cell: ({ row }) => (
                          <div>
                            <InderterminateCheckbox key={row.id} {...row.getToggleRowSelectedProps({ title : "Sélectionner "})} />
                          </div>
                        ),
                      }, 
                      ...columns  
                ]
            )
        }
        );

    useEffect(() => {
        props.onSelectionChange(selectedRowIds);
    }, [selectedRowIds]);


    const contextMenus = rows.map((row) => {
        return ContextMenu.Menu({ id : row.id });
    })

    const ContextMenusRender = () => {
        if(rows.length > 0){
            return contextMenus.map((c, id) => {
                const { Menu } = c;
                return (
                    <Menu key={id}>
                        <ContextMenu.Item onClick={() => {props.editUsers({ id })}}><span><i className="mdi mdi-pencil text-info"/> Editer</span></ContextMenu.Item>
                        <ContextMenu.Item onClick={() => {props.deleteUsers({ id })}}><span><i className="mdi mdi-close text-danger"/> Supprimer</span></ContextMenu.Item>
                    </Menu>
                )
            })
        }
        
    }

    const getEmptyContent = () => {
        return (
            <tr className="not-hoverable">
                <td className="text-center" colSpan={columns.length + 1}>Aucun autre administrateur</td>
            </tr>
        );
    }

    const getSkeleton = () => {
        let trs = [];
        for (let i = 0; i < props.skeleton; i++) {
            trs.push(
                <tr key={i}>
                    {[ <td key={columns.lenght + 1}>
                        <div className="form-check form-check-muted m-0">
                                <label className="form-check-label skeleton">
                                    <input type="checkbox" className="form-check-input" disabled/>
                                    <i className="input-helper" />
                                </label>
                            </div>
                        </td>,
                        ...columns.map((col, id) => {
                            switch(col.accessor){
                                case 'profilImage':
                                    return ( 
                                        <td key={id}>
                                            <img className="skeleton" alt=""/>
                                        </td>
                                    );
                                
                                default:
                                    return (
                                        <td key={id}>
                                            <p className="skeleton rnd"/>
                                        </td>
                                    );
                            }
                        })
                    ]}
                </tr>
            )
        }
        return trs;
    }

    return (
        <React.Fragment>
            <table className="table" {...getTableProps()}>
                <thead>
                    {// Loop over the header rows
                    headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps({ title : "Ordonner"}))} >
                            {column.render('Header')}
                            </th>
                        ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.length > 0 ?
                        rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} onContextMenu={contextMenus[row.id].show} data-selected={selectedRowIds.hasOwnProperty(row.id)}>
                                    {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                    })}
                                </tr>
                            )
                        })
                    : 
                        (props.skeleton ? getSkeleton() : getEmptyContent())
                    }
                </tbody>
            </table>
            {ContextMenusRender()}
        </React.Fragment>
    )
}
export default UsersTable