import React, { Component } from 'react';
import { setPageTitle } from '../functions/common';
import UsersTableComponent from '../components/pages/other-users/users-table';
import { Button } from 'react-bootstrap';
import AdminsCard from '../components/pages/other-users/admins.card';

class OtherUsers extends Component{
    constructor(props) {
        super(props);
        setPageTitle("Autres utilisateurs");
    }

    render(){
        return(
            <React.Fragment>
                <h2>Autres utilisateurs</h2>
                <div className="row">
                    <AdminsCard />
                </div>
            </React.Fragment>
        )
    }
}

export default OtherUsers;