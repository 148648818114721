import React, { Component } from 'react';
import UsersTable from './users-table';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { apiRequest } from '../../../functions/api';
import * as apiConst from '../../../constants/api/index'
import { connect } from 'react-redux';
import LoaderBox from '../../shared/loaders/loader.box';
import ConfirmDialog from '../../custom-boostrap/ConfirmDialog';
import { isEmpty, isValidEmail, isValidPhone, lengthObject } from '../../../functions/common';
import PasswordConfirmModalComponent from '../../shared/modals/password-confirm';
import { pushNotification } from '../../../functions/notifications';
import { deleteUsers__action, updateUsers__action } from '../../../actions/users.actions';
const CREATE_MODE = "CREATE_MODE";
const EDIT_MODE = "EDIT_MODE";

class AdminsCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded : false,
            loading : true,
            loadingMess : "",
            addEnable : true,
            editEnable : false,
            deleteEnable : false,
            deleteModalShow : false,
            editModal : {
                show : false,
                loading: false,
                mess : "",
                isValid : false,
                name : "",
                firstName : "",
                email : "",
                phone : "",
                disableAccount : false,
                alertUser : false,
                guide : false,
                currentUser : {},
                errors : {}

            },
            confirmPasswordModalShow : false,
            users : []
        }
        this.table = {
            props : {
                onSelectionChange : this.onSelectionChange.bind(this),
                deleteUsers : this.deleteUsers.bind(this),
                editUsers : this.launchUserEdition.bind(this)
            },
            selection : {}
        }
        this.deleteModal = {
            title : "",
            content : "",
            onConfirm : () => {},
            onHide : function(){
                this.setState({ deleteModalShow : false });
            }.bind(this)
        }
        this.confirmPasswordModal = {
            onValid : () => {},
            onDecline : () => { this.setState({ deleteModalShow : false, confirmPasswordModalShow : false })}
        }
        this.editModal = {
            mode : CREATE_MODE,
            title : ""
        }
    }
    errors = {
        empty : "EMPTY",
        alreadyEmail : "ALREADY_EMAIL",
        emailSyntaxe : "EMAIL_SYNTAXE",
        alreadyPhone : "ALREADY_PHONE",
        phoneSyntaxe : "PHONE_SYNTAXE"
    }
    
    getEditModalState(state){
        return { ...this.state.editModal, ...state }
    }
    getUsers() {
        this.setState({ loading : true });
        apiRequest({
            url : '/api.users/get',
            data : {
                filters : {
                    type : [apiConst.ADMIN]
                }
            }
        }).then((res) => {
            if(res.data.http_code === 200){
                if(res.data.data.users){
                    let users = [];
                    let usersState = {};
                    res.data.data.users.forEach(user => {
                        if(user.UID !== this.props.app.user){
                            users.push(user)
                        }
                        usersState[user.UID] = {...user};
                    });
                    this.props.dispatch(updateUsers__action(usersState));
                    this.setState({ 
                        users,
                        loaded : true,
                        loading : false
                    });
                }
            }
        })
    }
    onSelectionChange(selection){
        if(selection !== this.table.selection){
            this.table.selection = selection;
            const selectionLength = lengthObject(selection)
            this.setState({  
                editEnable : selectionLength === 1,
                deleteEnable : selectionLength > 0
            });
        }
    }
    deleteUsers({ id }){
        const s = this.table.selection;
        const deleteUser = (users) => {
            const usersUIDS = users.map((user) => user.UID);
            
            const sendToAPI = ({ token, duration }) => {
                this.setState({ confirmPasswordModalShow : false, loading: true, loadingMess : "Suppression en cours" });
                const notifErr = (mess, icon = "mdi mdi-close") => {
                    pushNotification({
                        type : "error",
                        title : "Erreur",
                        icon,
                        content : <p>{mess}</p>
                    });
                    this.setState({ loading: false });
                }
                apiRequest({
                    url : '/api.users/delete',
                    data : {
                        validationToken : token,
                        UIDS : usersUIDS
                    }
                })
                .then((res) => {
                    if(res.data.http_code === 200){
                        if(res.data.status === "success"){
                            pushNotification({
                                type : "success",
                                title : "Suppression d'utilisateurs",
                                icon : "mdi mdi-account-multiple-minus",
                                content : <p>Les utilisateurs demandés ont été supprimés avec succès.</p>
                            });
                            let users = []
                            this.state.users.forEach((user) => {
                                if(usersUIDS.indexOf(user.UID) < 0){
                                    users.push(user);
                                }
                            })
                            this.table.selection = {};
                            this.setState({ 
                                users, 
                                loading: false,
                                editEnable : false,
                                deleteEnable : false
                            });
                            this.props.dispatch(deleteUsers__action(usersUIDS));
                        }else{
                            notifErr(`Une erreur est survenue empêchant la suppression des utilisateurs. (Code : ${res.data.status_code})`)
                        }
                    }else{
                        notifErr(`Une erreur est survenue empêchant la suppression des utilisateurs. (Code : ${res.data.http_code})`, "mdi mdi-cloud-outline")
                    }
                })
            }
            this.confirmPasswordModal.onValid = sendToAPI;
            this.setState({ confirmPasswordModalShow : true, deleteModalShow : false });
        }
        if(lengthObject(s) === 1 || typeof id === "number"){
            let user = {}
            if (lengthObject(s) > 0){
                user = this.state.users[Object.keys(s)[0]];
            }else{
                user = this.state.users[id];
            }
            
            this.deleteModal.title = "Supprimer un utilisateur";
            this.deleteModal.content = `Voulez-vous vraiment supprimer le compte de ${user.firstName} ${user.name} ? L'utilisateur sera averti de votre action.`;
            this.deleteModal.onConfirm = () => { deleteUser([user]) }
            this.setState({ deleteModalShow : true });
            
        }else if(lengthObject(s) > 1){
            let indexes = Object.keys(s);
            let users = indexes.map((i) => this.state.users[i]);

            this.deleteModal.title = "Supprimer des utilisateurs";
            this.deleteModal.content = `Voulez-vous vraiment supprimer le compte des ${lengthObject(s)} utilisateurs sélectionnés ? Ils seront avertis de votre action.`;
            this.deleteModal.onConfirm = () => { deleteUser(users) }
            this.setState({ deleteModalShow : true });
        }
    }
    launchUserAdding(){
        this.editModal.title = "Ajouter un administrateur";
        this.editModal.mode = CREATE_MODE;
        this.setState({ 
            editModal : this.getEditModalState({ 
                show : true,
                loading : false,
                loadingMess : "",
                email : "",
                name : "",
                phone : "",
                firstName : "",
                errors : {},
                currentUser : {},
                disableAccount : false,
                alertUser : true
            })
        })
    }
    launchUserEdition({ id = false }){
        if(id === false){
            id = Object.keys(this.table.selection)[0]
        }
        const user = this.state.users[id];

        this.editModal.title = "Editer un administrateur";
        this.editModal.mode = EDIT_MODE;
        this.setState({
            editModal : this.getEditModalState({ 
                show : true,
                loading : false,
                loadingMess : "",
                email : user.email,
                name : user.name,
                phone : user.phone,
                firstName : user.firstName,
                errors : {},
                currentUser : user,
                disableAccount : !user.active,
                guide : user.guide,
                isValid : false
            })
        })

    }
    getEditModal(){
        const setControlErrors = (errors = []) => {
            let errorsWithMessages = new Map();
            errors.forEach(error => {
                errorsWithMessages.set(error[0], error[1]);
            });
            return errorsWithMessages;
        }

        const setFeedBack = ({ name, errors = new Map()}) => {
            if(this.state.editModal.errors[name]){
                return (
                    <React.Fragment>
                        <Form.Control.Feedback type="invalid">{errors.get(this.state.editModal.errors[name]) }</Form.Control.Feedback>
                    </React.Fragment>
                )
            }
        }
        const handleChange = (value, prop) => {
            let state = this.state.editModal;
            state[prop] = value;
            state.errors = {};
            let isValid = true;

            const setError = (err) => {
                state.errors[err.property] = err.code;
                isValid = false;
            }
        

            if(isEmpty(state.name)) { setError({ property : 'name', code : this.errors.empty }) }

            if(isEmpty(state.firstName)) { setError({ property : 'firstName', code : this.errors.empty }) }

            if(isEmpty(state.email)) { setError({ property : 'email', code : this.errors.empty }) }
            else if(!isValidEmail(state.email)){ setError({ property : 'email', code : this.errors.emailSyntaxe }) }

            if(isEmpty(state.phone)){ setError({ property : 'phone', code : this.errors.empty }) }
            else if(!isValidPhone(state.phone)){ setError({ property : 'phone', code : this.errors.phoneSyntaxe }) }

            if(isValid){
                isValid = (
                    this.state.editModal.currentUser.name != state.name ||
                    this.state.editModal.currentUser.firstName != state.firstName ||
                    this.state.editModal.currentUser.email != state.email ||
                    this.state.editModal.currentUser.phone != state.phone ||
                    this.state.editModal.currentUser.active != !state.disableAccount  ||
                    this.state.editModal.currentUser.guide !== state.guide
                )
            }
            state.isValid = isValid;

            this.setState({
                editModal : state
            });
        }
        const valid = () => {
            if(this.state.editModal.isValid){
                if(this.editModal.mode === CREATE_MODE){
                    this.setState({
                        editModal : this.getEditModalState({
                            loading : true,
                            loadingMess : "Ajout de l'utilisateur"
                        })
                    })
                    apiRequest({
                        url : '/api.users/post',
                        data : {
                            user : {
                                name : this.state.editModal.name,
                                firstName : this.state.editModal.firstName,
                                email : this.state.editModal.email,
                                phone : this.state.editModal.phone,
                                active : !this.state.editModal.disableAccount,
                                guide : this.state.editModal.guide
                            },
                            type : apiConst.ADMIN,
                            alertUser : (this.state.editModal.disableAccount ? false : this.state.editModal.alertUser),
                        }
                    })
                    .then((res) => {
                        if(res.data.http_code === 200){
                            if(res.data.status === "success" && res.data.data.user){
                                this.setState({
                                    users : [...this.state.users, res.data.data.user],
                                    editModal : this.getEditModalState({ loading : false, show : false })
                                });
                                this.props.dispatch(updateUsers__action({ [res.data.data.user.UID] : {...res.data.data.user} }));
                                pushNotification({
                                    type : "success",
                                    title : "Administrateur ajouté",
                                    icon : "mdi mdi-account-plus",
                                    content : <p>L'administrateur à été ajouté avec succès !</p>
                                })

                            }else{
                                if(res.data.errors){
                                    this.setState({
                                        editModal : this.getEditModalState({ 
                                            loading : false, 
                                            errors : res.data.errors 
                                        })
                                    })
                                }else{
                                    this.setState({
                                        editModal : this.getEditModalState({ 
                                            loading : false
                                        })
                                    })
                                    pushNotification({
                                        type : "error",
                                        title : "Erreur",
                                        icon : "mdi mdi-close",
                                        content : <p>{`Une erreur est survenue. Réessayez. (Code : ${res.data.status_code})`}</p>
                                    })
                                }
                            }
                        }else{
                            this.setState({
                                editModal : this.getEditModalState({ loading : false })
                            })
                            pushNotification({
                                type : "error",
                                title : "Erreur",
                                icon : "mdi mdi-close",
                                content : <p>{`Une erreur est survenue. Réessayez. (Code : ${res.data.http_code})`}</p>
                            })
                        }

                    })
                }else if(this.editModal.mode === EDIT_MODE){
                    this.setState({
                        editModal : this.getEditModalState({
                            loading : true,
                            loadingMess : "Modification de l'utilisateur"
                        })
                    })
                    apiRequest({
                        url : '/api.users/put',
                        data : {
                            user : {
                                UID : this.state.editModal.currentUser.UID,
                                name : this.state.editModal.name,
                                firstName : this.state.editModal.firstName,
                                email : this.state.editModal.email,
                                phone : this.state.editModal.phone,
                                active : !this.state.editModal.disableAccount,
                                guide : this.state.editModal.guide
                            }
                        }
                    })
                    .then((res) => {
                        if(res.data.http_code === 200){
                            if(res.data.status === "success" && res.data.data.user){
                                this.setState({
                                    users : this.state.users.map((u) => ( u.UID === res.data.data.user.UID ? res.data.data.user : u)),
                                    editModal : this.getEditModalState({ loading : false, show : false })
                                });
                                this.props.dispatch(updateUsers__action({ [res.data.data.user.UID] : {...res.data.data.user} }));
                                pushNotification({
                                    type : "success",
                                    title : "Modifications enregistrées",
                                    icon : "mdi mdi-content-save",
                                    content : <p>Les informations sur l'administrateur ont été enregistrées !</p>
                                })

                            }else{
                                switch (res.data.status_code) {
                                    case apiConst.NO_ACCOUNT:
                                        pushNotification({
                                            type : "error",
                                            title : "Erreur",
                                            icon : "mdi mdi-close",
                                            content : <p>Le compte utilisateur n'existe pas ou à été supprimé.</p>
                                        })
                                        this.setState({
                                            editModal : this.getEditModalState({ 
                                                errors : (res.data.errors ? res.data.errors : this.state.editModal.errors),
                                                loading : false,
                                                show : false
                                            })
                                        })
                                        break;
                                
                                    default:
                                        pushNotification({
                                            type : "error",
                                            title : "Erreur",
                                            icon : "mdi mdi-close",
                                            content : <p>{`Une erreur est survenue. Réessayez. (Code : ${res.data.status_code})`}</p>
                                        })
                                        this.setState({
                                            editModal : this.getEditModalState({ 
                                                errors : (res.data.errors ? res.data.errors : this.state.editModal.errors),
                                                loading : false
                                            })
                                        })
                                        break;
                                }
                            }
                        }else{
                            this.setState({
                                editModal : this.getEditModalState({ loading : false })
                            })
                            pushNotification({
                                type : "error",
                                title : "Erreur",
                                icon : "mdi mdi-close",
                                content : <p>{`Une erreur est survenue. Réessayez. (Code : ${res.data.http_code})`}</p>
                            })
                        }
                    })
                }
             }
        }
        const cancel = () => { 
            this.setState({ editModal : this.getEditModalState({ show : false }) });
        }
        return(
            <Modal size="md" show={this.state.editModal.show} onHide={() => {}}>
                <Modal.Header>{this.editModal.title}</Modal.Header>
                <LoaderBox show={this.state.editModal.loading} mess={this.state.editModal.loadingMess}>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="form-group">
                                <Form.Label>Nom</Form.Label>
                                <Form.Control type="text" value={this.state.editModal.name} onChange={(e) => handleChange(e.target.value, "name")}/>
                                {setFeedBack({ name : "name", errors : setControlErrors([[this.errors.empty, "Entrez un nom"]]) })}
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label>Prénom</Form.Label>
                                <Form.Control type="text" value={this.state.editModal.firstName} onChange={(e) => handleChange(e.target.value, "firstName")}/>
                                {setFeedBack({ name : "firstName", errors : setControlErrors([[this.errors.empty, "Entrez un prénom"]]) })}
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" value={this.state.editModal.email} onChange={(e) => handleChange(e.target.value, "email")}/>
                                {setFeedBack({ name : "email", errors : setControlErrors([[this.errors.empty, "Entrez un email"], [this.errors.emailSyntaxe, "Email invalide"], [this.errors.alreadyEmail, "Email déjà utilisé"]]) })}
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label>Téléphone</Form.Label>
                                <Form.Control type="phone" value={this.state.editModal.phone} onChange={(e) => handleChange(e.target.value, "phone")}/>
                                {setFeedBack({ name : "phone", errors : setControlErrors([[this.errors.empty, "Entrez un numéro de téléphone"], [this.errors.phoneSyntaxe, "Numéro invalide"], [this.errors.alreadyPhone, "Numéro déjà utilisé"]]) })}
                            </Form.Group>
                            <div className="form-check">
                                <label className="form-check-label text-muted">
                                    <input type="checkbox" className="form-check-input" checked={this.state.editModal.guide} onChange={() => handleChange(!this.state.editModal.guide, "guide")}/>
                                    <i className="input-helper"></i>
                                    L'utilisateur est aussi un accompagnateur
                                </label>
                            </div>
                            {this.editModal.mode === CREATE_MODE ? 
                                <div className="form-check">
                                    <label className="form-check-label text-muted">
                                        <input type="checkbox" className="form-check-input" disabled={this.state.editModal.disableAccount} checked={this.state.editModal.alertUser && !this.state.editModal.disableAccount} onChange={() => handleChange(!this.state.editModal.alertUser, "alertUser")}/>
                                        <i className="input-helper"></i>
                                        Avertir l'utilisateur de la création du compte
                                    </label>
                                </div>
                            : null }
                            <div className="form-check form-check-danger">
                                <label className="form-check-label text-muted">
                                    <input type="checkbox" className="form-check-input" checked={this.state.editModal.disableAccount} onChange={() => handleChange(!this.state.editModal.disableAccount, "disableAccount")}/>
                                    <i className="input-helper"></i>
                                    { this.editModal.mode === CREATE_MODE ? "Ne pas activer le compte" : "Désactiver le compte" }
                                </label>
                            </div>
                        </Form>
                    </Modal.Body>
                </LoaderBox>
                <Modal.Footer>
                    <Button variant={this.editModal.mode === CREATE_MODE ? "success" : "primary"} disabled={!this.state.editModal.isValid || this.state.editModal.loading} onClick={valid.bind(this)}>{this.editModal.mode === CREATE_MODE ? "Créer" : "Enregistrer"}</Button>
                    <Button variant="secondary" onClick={cancel.bind(this)} disabled={this.state.editModal.loading}>Annuler</Button>
                </Modal.Footer>
            </Modal>
        )
    }
    corps(){
        return (
            <UsersTable data={this.state.users} selection={this.table.selection} {...this.table.props}/>
        );
    }
    skeleton(){
        return (
            <UsersTable data={this.state.users} skeleton={5} {...this.table.props}/>
        );
    }
    componentDidMount(){
        this.getUsers();
    }
    
    render(){
        return ( 
            <div className="col grid-margin stretch-card">
                <LoaderBox show={this.state.loading && this.state.loaded} className="card" mess={this.state.loadingMess} >
                    <div className="card">
                        <div className="card-body">
                            <div className="card-title">Administrateurs</div>
                            <div className="btn-container-inline-left">
                                <Button variant="primary" disabled={!this.state.addEnable || !this.state.loaded} onClick={this.launchUserAdding.bind(this)}><i className="mdi mdi-plus"/>Ajouter</Button>
                                <Button variant="info" disabled={!this.state.editEnable || !this.state.loaded} onClick={this.launchUserEdition.bind(this)}><i className="mdi mdi-pencil" />Editer</Button>
                                <Button variant="danger" disabled={!this.state.deleteEnable || !this.state.loaded} onClick={this.deleteUsers.bind(this)}><i className="mdi mdi-delete-forever" />Supprimer</Button>
                            </div>
                            {this.state.loaded ? this.corps() : this.skeleton()}
                        </div>
                    </div>
                </LoaderBox>
                <PasswordConfirmModalComponent show={this.state.confirmPasswordModalShow} {...this.confirmPasswordModal}/>
                <ConfirmDialog 
                    show={this.state.deleteModalShow}
                    onHide={this.deleteModal.onHide}
                    modalTitle={this.deleteModal.title}
                    confirm={{ variant : "danger", text : "Supprimer", onClick : this.deleteModal.onConfirm }}
                    decline={{ text : "Annuler" }}
                >
                    {this.deleteModal.content}
                </ConfirmDialog>
                {this.getEditModal()}
            </div> )
    }
};

const mapStateToProps = (state) => ({ app : state.app });
export default connect(mapStateToProps, null)(AdminsCard);