import React, { Component } from "react";
import PageInBuilding from "../../components/pages/building/page";
import { setPageTitle } from "../../functions/common";

class SejoursPrices extends Component{
    constructor(props) {
        super(props);
        setPageTitle("Tarifs | Séjours");
    }

    render(){
        return <PageInBuilding />
    }
}

export default SejoursPrices;