export const themeColors = {
  primary: "#0090e7",
  secondary: "#e4eaec",
  success: "#0fa319",
  info: "#8f5fe8",
  warning: "#ffab00",
  danger: "#fc424a",
  light: "#ffffff",
  dark: "#0d0d0d",
};
