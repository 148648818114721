import * as userActions from '../constants/users.actions';
import * as appActions from '../constants/app.actions'

const initialState = {

};

export default function users(state = initialState, action) {
    switch(action.type){

        case userActions.UPDATE_USERS :
        case userActions.GET_USERS :
            state = { ...state, ...action.payload.users };
        
        case appActions.SET_USER :
        case appActions.UPDATE_USER :
        case appActions.GET_USER :
            if (action.payload.user) {
                if (action.payload.user.UID) {
                    return { ...state, [action.payload.user.UID] : action.payload.user }
                }
            }
            return (state);

        case userActions.DELETE_USERS:
            const n_state = { ...state };
            action.payload.UIDS.forEach((UID) => {
                if (UID in n_state)
                    delete n_state[UID];
            });
            return (n_state);
        default:
            return state;
    }
}