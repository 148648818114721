import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { apiRequest } from '../functions/api';
import { setPageTitle } from '../functions/common';

class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show : true
        }
        setPageTitle("Déconnexion");
    }


    handleClose(){ 
        this.setState({ show : false });
    }
    disconnect(){
        apiRequest({
            url : '/auth.logout/get'
        })
        .then((res) => {
            if(res.data.http_code === 200){
                window.location = '/login';
            }
        })
    }
    cancel(){
        this.handleClose();
        this.props.history.goBack();
    } 

    render(){
        return (
            <div>
            <Modal show={this.state.show} onHide={this.cancel.bind(this)}>
                <Modal.Header closeButton>Se déconnecter ?</Modal.Header>
                <Modal.Body>
                    <p>Voulez-vous vraiment vous déconnecter ?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.cancel.bind(this)}>Non</Button>
                    <Button variant="primary" onClick={this.disconnect.bind(this)}>Oui</Button>
                </Modal.Footer>
            </Modal>
            </div>
        );
    }
};

export default withRouter(Logout);