import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteSejoursEvents__action } from "../../../../../../actions/sejours.events.actions";
import ConfirmDialog from "../../../../../../components/custom-boostrap/ConfirmDialog";
import { apiRequest } from "../../../../../../functions/api";
import { pushNotification } from "../../../../../../functions/notifications";
import { getDeepState, setDeepState } from "../../../../../../functions/react.utilities";

class DeleteModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      pendingSelection : {}
    }

    this.getDeepState = getDeepState(this);

    this.pState = () => ({ ...this.props.parentState });
    this.pSetState = this.props.parentSetState;
    this.pSetDeepState = this.props.parentSetDeepState;
    this.pGetDeepState = this.props.parentGetDeepState;

    this.pSetState = this.pSetState.bind(this);
    this.pSetDeepState = this.pSetDeepState.bind(this);
    this.pGetDeepState = this.pGetDeepState.bind(this);

    this.cancel = this.cancel.bind(this);
    this.deleteEvents = this.deleteEvents.bind(this);
  }

  deleteEvents(){
    const parentState = this.pState();
    if(parentState.selection.length > 0){
        const newParentState = { ...parentState };
        const newState = { ...this.state };
        parentState.selection.forEach((EID) => {
          newParentState.loaders[EID] = { show : true, mess : "Suppression en cours..." }
        });
        newParentState.selection = [];

        const pendingKeys = Object.keys(this.state.pendingSelection);
        const pendingSelectionID = (pendingKeys.length > 0) ? (parseInt(pendingKeys[pendingKeys.length - 1]) + 1).toString() : "0";
        newState.pendingSelection[pendingSelectionID] = parentState.selection;
    
        newParentState.modals.delete = false;

        apiRequest({
            url : "/sejours.events/delete",
            data : {
                EIDS : newState.pendingSelection[pendingSelectionID]
            }
        })
        .then((res) => {
            const newParentState = this.pGetDeepState();
            const newState = this.getDeepState();
            const selection = [...this.state.pendingSelection[pendingSelectionID]];
            const errorNotification = (m) => pushNotification({
                type : "error",
                title : `Suppression ${(selection.length > 1) ? "des événements" : "de l'événement"} impossible`,
                icon : "mdi mdi-close",
                content : <p>{m}</p>
            });

            if(res.data.http_code === 200){
                if(res.data.status === "success"){
                    if(res.data.data.deleted){
                        this.props.dispatch(deleteSejoursEvents__action(selection));
                        pushNotification({
                            type : "success",
                            title : (selection.length > 1) ? "Événements supprimés" : "Événement supprimé",
                            icon : "mdi mdi-delete-forever",
                            content : <p>{`${(selection.length > 1) ? "Événements supprimés" : "Événement supprimé"} avec succès !`}</p>
                        });
                    }else{
                        errorNotification(`Erreur du serveur (UNKNOWN)`);
                    }
                }else{
                    errorNotification(`Erreur du serveur (${res.data.status_code})`);
                }
            }else{
                errorNotification(`Erreur du serveur (${res.data.status_code})`);
            }

            //Annulation des loaders
            selection.forEach((e) => {
                newParentState.loaders[e] = {
                    show : false,
                    mess : ""
                }
            });
            //Nettoyage de la sélection d'attente
            delete newState.pendingSelection[pendingSelectionID];
            this.setState(newState);
            this.pSetState(newParentState);
        });

        this.setState(newState);
        this.pSetState(newParentState);
    }
  }

  cancel(){
    this.pSetDeepState(s => s.modals.delete = false);
  }

  render() {
    const pState = this.pGetDeepState();
    return (
      <ConfirmDialog 
          show = {this.props.show}
          modalTitle = "Supprimer des événements"
          decline = {{ text : "Annuler", variant : "secondary", onClick : this.cancelDeleteEvents }}
          confirm = {{ text : "Valider", variant : "danger", onClick : this.deleteEvents }}
          onHide = {this.cancel}
      >
          <p><i className="mdi mdi-alert text-danger"/> Attention, la supression d'événements étant encore icomplète et en construction, si vous supprimez un événement qui possède des inscrits, il ne sera plus récupérable.</p>
          <p>Supprimez des événements seulement si vous êtes certains de ce que vous faites.</p>
          <p>Supprimer {`${(pState.selection.length > 1) ? `les ${pState.selection.length} événements sélectionnés` : "l'événement sélectionné"} ?`}</p>
      </ConfirmDialog>
    );
  }
}

const mapStateToProps = (state) => ({ sejours : state.sejours });
export default connect(mapStateToProps, null)(DeleteModal);