import * as appActions from '../constants/app.actions';

const initialState = {
    user : null,
    loggedIn : false
};

export default function app(state = initialState, action) {
    switch(action.type){
        case appActions.SET_USER:
            const { user, loggedIn } = action.payload;
            const n_state = {...state, loggedIn};
            if (user) {
                if (user.UID) {
                    n_state.user = user.UID;
                }
            }
            return (n_state);
        default:
            return state;
    }
}