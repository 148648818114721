import React, { Component } from 'react';
import { Form } from 'react-bootstrap';


export class CFormControlPassword extends Component{
    constructor(props) {
        super(props);
        this.state= {
            show : false
        }
    }
    
    getProps(){
        if(this.props.showOnPress){
            this.onMouseDown = () => { this.setState({ show : true }) }
            this.onMouseUp = () => { this.setState({ show : false }) }
            this.onClick = () =>  {}
        }else if(!this.props.noShow){
            this.onMouseDown = () => {}
            this.onMouseUp = () => {}
            this.onClick = () =>  { this.setState({ show : !this.state.show })}
        }

        let inputProps = { ...this.props }
        delete inputProps.type;
        delete inputProps.showOnPress;
        delete inputProps.noShow;

        return inputProps
    }

    render(){
        let props = this.getProps();
        return (
            <React.Fragment>
                <div className="password-viewer">
                    {!this.props.noShow ? <button className="view-password" type="button" onMouseDown={this.onMouseDown} onClick={this.onClick} onMouseUp={this.onMouseUp} title={this.state.show ? "Cacher le mot de passe" : "Afficher le mot de passe"}><i className={this.state.show ? "mdi mdi-eye-off" : "mdi mdi-eye"}/></button> : null}
                    <Form.Control type={this.state.show ? 'text' : 'password'} {...props} />
                </div>
            </React.Fragment>
        )
    }
}
