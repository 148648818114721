import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { mergeProps } from '../../../functions/react.utilities';

class ButtonInputFile extends Component{
    constructor(props){
        super(props);
        this.buttonProps = this.getButtonProps();
        this.inputProps = this.getInputProps();
        this.state = {
            files : []
        }
        if(!this.props.onChange) this.props.onChange = () => {}
    }
    getButtonProps() {
        return mergeProps({
            sameNameProps : ["active", "block", "size", "variant", "disabled", "className"],
            notSameNameProps : [["idForButton", "id"]],
            props : this.props
        });
    }
    getInputProps() {
        return mergeProps({
            sameNameProps : ["type", "accept", "capture", "multiple"],
            notSameNameProps : [["idForInput", "id"]],
            props : this.props
        });
    }


    onChange(e){
        e.persist();
        this.files = e.target.files;
        this.setState({ files : e.target.files });
        this.props.onChange({ files : this.files, event : e });
    }
    render(){
        return(
            <Button className="btn-file-picker" {...this.buttonProps}>
                <label>
                    <span>{this.props.children}</span>
                    <input type="file" hidden {...this.inputProps} onChange={this.onChange.bind(this)}/>
                </label>
            </Button>
        )
    }
}

export default ButtonInputFile;